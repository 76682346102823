import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header from "../../Component/Common/Header/Header"
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import firebase from '../../firebase';
import fetchCrops from '../../util/fetchCrops';
import { useEffect } from 'react';
function AddItemToStore() {
    const [crops, setCrops] = useState([])
    const [{ user, category }] = useDataLayerValue()
    const [itemDetail, setItemDetail] = useState({
    })
    const [isUploading, setIsUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)

    useEffect(() => {
        const loadData = async () => {
            setCrops(await fetchCrops())
        }
        loadData()
    }, [])
    const navigate = useNavigate()
    let name, value;
    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setItemDetail({ ...itemDetail, [name]: value });
    }
    const imgExtRemover = () => {
        var fileName = itemDetail.item_image.name;
        return fileName.split('.').pop()
    }


    const addItemInQueue = (e) => {
        e.preventDefault()
        var itemLocalDetail = itemDetail
        if (itemLocalDetail.unit === undefined) {
            itemLocalDetail.unit = "kg"
        }

        var TIME_STEMP = Date.now()
        firebase.storage().ref("items/").child(`${TIME_STEMP}.${imgExtRemover()}`).put(itemLocalDetail.item_image).on(
            "state_changed",
            (snapshot) => {
                setIsUploading(true)
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress)
            },
            (error) => {
                console.error(error)
            },
            (event) => {
                firebase.storage().ref('/items').child(`${TIME_STEMP}.${imgExtRemover()}`).getDownloadURL().then((event) => {
                    delete itemLocalDetail.item_image
                    var sellerName = ""
                    if (user.userData.userType === "corporate") {
                        sellerName = user.userData.corporateData.name
                    }
                    else {
                        sellerName = user.userData.name
                    }
                    firebase.database().ref('item-to-verify').push({
                        ...itemLocalDetail,
                        cropName: itemLocalDetail.customCropName?itemLocalDetail.customCropName:itemLocalDetail.cropName,
                        imgUrl: event,
                        sellerUID: user.userAuthData.uid,
                        timeStamp: TIME_STEMP,
                        block: user.userData.block ? user.userData.block : null,
                        district: user.userData.district,
                        state: user.userData.state,
                        sellerName: sellerName,
                        userType: user.userData.userType
                    }).then(() => {
                        setIsUploading(false)
                        Swal.fire("Item Registerd Successfully!", '', 'success').then(() => {
                            navigate("/my-store/shop")
                        })
                    })
                })
            }
        )
    }
    return (
        <>
            <Header />
            <div className="container mt-5" style={{ marginBottom: 80 }}>
                <br /> <br />
                {
                    isUploading &&
                    <div className="backdrop">
                        <div className="progress-bar">
                            <div style={{ width: `${uploadProgress}%` }} className="progress-bar-child">
                            </div>
                        </div>
                    </div>
                }
                <form className="my-4" action="" onSubmit={addItemInQueue}>
                    <div className="text-danger text-center">
                        keep updating product in every three days if not will get deleted.
                        हर तीन दिनों में उत्पाद को अपडेट करते रहें, यदि नहीं तो हटा दिया जाएगा
                    </div>
                    <br />
                    <select required onChange={handleFormChanges} className="form-control" name="category" id="">
                        <option value="">Select Category - फसल का प्रकार</option>
                        {
                            user.userData.userType === "farmer" ?
                                <>
                                    {
                                        category.map((item, index) => {
                                            if (
                                                item.categorieName !== "Fertilizers & Pesticides - उर्वरक & कीटनाशक"
                                                &&
                                                item.categorieName !== "Farm Machinery - कृषि मशीनरी"
                                                &&
                                                item.categorieName !== "Irrigation Equipment - सिंचाई उपकरण"
                                            ) {
                                                return <>
                                                    <option key={index} value={item.categorieName}>{item.categorieName}</option>
                                                </>
                                            }
                                        })
                                    }
                                </>
                                :
                                <>
                                    {
                                        category.map((item, index) => {
                                            return (
                                                <option key={index} value={item.categorieName}>{item.categorieName}</option>
                                            )
                                        })
                                    }
                                </>
                        }

                    </select>
                    <div className="form-group my-4">
                        <select required name="cropName" className="inputs-reg"
                            disabled={itemDetail.category === undefined || itemDetail.category === "" ? true : false}
                            onChange={handleFormChanges} id="inputState">
                            <option value="">Item Name - फसल का नाम</option>
                            <option value="custom">Custom</option>

                            {
                                crops.map((item, index) => {
                                    if (item.category === itemDetail.category) {
                                        return <>
                                            <option value={item.cropName}>{item.cropName}</option>
                                        </>
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </select>
                    </div>
                    {
                        itemDetail.cropName === "custom" &&


                        <input onChange={handleFormChanges} required
                            className="form-control" placeholder="Item Name" name="customCropName" type="text" />
                    }
                    <div className="form-group my-4">

                        <input onChange={handleFormChanges}
                            className="form-control" placeholder="Variety - Make - फसल का किस्म" name="variety" type="text" />
                    </div>
                    <div className="form-group my-4">

                        <textarea onChange={handleFormChanges}
                            className="form-control" name="description" type="text" placeholder="Description - उत्पाद का अन्य विवरण" />
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-6 my-4">

                            <input onChange={handleFormChanges}
                                required
                                className="form-control" name="price" type="number" placeholder="Price - मूल्य" />
                        </div>
                        <div className="form-group col-lg-6 my-4">

                            <select required name="unit" className="form-control" onChange={handleFormChanges} >
                                <option value="Not Selected">Unit - किलो या अन्य माप में बेचना</option>
                                <option value="kg">per kg</option>
                                <option value="liter">per liter(L)</option>
                                <option value="quintal">per quintal(100 KG)</option>
                                <option value="100grams">per 100grams</option>
                                <option value="250grams">per 250grams</option>
                                <option value="500grams">per 500grams</option>
                                <option value="dozen">per dozen(12 Pieces)</option>
                                <option value="piece">per piece</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group my-4">
                        <label className="form-label"></label>
                        <input onChange={handleFormChanges}
                            required
                            className="form-control" name="quantity" type="number" placeholder="Available Quantity for sell - कितना बेचना" />
                    </div>

                    <h5>Is this product oraganic? - जैविक है क्या?</h5>
                    <input required type="radio" onChange={handleFormChanges} className="form-check-input" id="Yes" name="organic" value="yes" />
                    <label className="form-check-label" for="Yes"> Yes </label>


                    <input required type="radio" onChange={handleFormChanges} className="form-check-input" id="No" name="organic" value="no" />
                    <label className="form-check-label" for="No"> No</label>
                    {
                        itemDetail.organic === "yes" &&
                        <div className="form-group my-4">
                            <label className="form-label">Your Organic Certificate No.</label>
                            <input onChange={handleFormChanges}
                                required
                                className="form-control" name="certificateNo" type="number" />
                        </div>
                    }
                    <div className="form-group my-4">
                        <label className="form-label">Item Image - उत्पाद की फोटो</label>
                        <input onChange={(e) => { setItemDetail({ ...itemDetail, item_image: e.target.files[0] }) }}
                            required
                            className="form-control" name="item_image" type="file" />
                    </div>


                    <button className="btn  btn-success" type="submit"  >Add Item - फसल डालें<i className="fa fa-upload"></i></button>

                </form>
            </div>
        </>
    )
}

export default AddItemToStore
