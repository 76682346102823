import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Header from "../../Component/Common/Header/Header"
import InfoPage from '../../Component/Common/UI Components/InfoPage'
import Loading from '../../Component/Common/Loading'
import { useDataLayerValue } from '../../DataLayer/DataLayer'
import firebase from "../../firebase"
import "../../style/seller-placed-order.css"
import InfoMessage from '../../Component/Common/UI Components/InfoMessage'
import Footer from '../../Component/Footer'
function SellerPlacedOrders() {
  const [{ user, authInitilize }] = useDataLayerValue()

  if (authInitilize || user.userData === undefined) {
    return <Loading />
  }
  else if (user.userData.userType === "farmer" || user.userData.userType === "corporate") {
    if (user.userData.farmerData || user.userData.corporateData) {

      return (
        <OrderTable />
      )


    }
    else {
      return (
        <InfoPage title="Your store is locked" desc="You have to make your seller profile to unlock the store" href={"/profile"} />
      )
    }
  }
  else {
    return (
      <InfoPage title="You are at the worng page" desc="This store is only for sellers" href={"/home"} />

    )
  }


}
const OrderTable = () => {
  const [{ user }] = useDataLayerValue()
  const [orders, setOrders] = useState([])
  useEffect(() => {
    const sellerOrders = user.userData.orders_got;
    const tempArr = []
    for (let id in sellerOrders) {
      tempArr.push({ ...sellerOrders[id], id })
    }
    setOrders(tempArr)
  }, [])
  if (orders.length) {
    return (
      <>
        <Header />
        <h3 className='orders-head'>Orders You Got</h3>
        <div className='seller-place-orders'>
          <table>
            <thead className='table-head'>
              <th className='radius-left'>Sr</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Buyer Address</th>
              <th>Price</th>
              <th className='radius-right text-center' colSpan={2}>Action</th>
            </thead>
            {
              orders.map((item, index) => {
                return (
                  <TableTr index={index} item={item} />
                )
              })
            }
          </table>
        </div>
      </>
    )
  }
  else {
    return (
      <>
        <Header />
        <InfoMessage
          title={"You didn't got any orders yet"}
          icon="fa-info-circle"
        />
        <Footer />
      </>

    )
  }
}
const TableTr = ({ item, index }) => {
  const [buyerData, setBuyerData] = useState([])
  const [{ user }] = useDataLayerValue()
  useEffect(() => {
    firebase.database().ref("users").child(item.userId).on("value", (snapshot) => {
      setBuyerData(snapshot.val())
    })
  }, [])
  const refuse = () => {

  }
  const accept = async () => {
    const { value: days } = await Swal.fire({
      title: 'Days you need to ready the order',
      input: 'number',
      inputLabel: 'How much Time it will take to ready the order?',
      inputPlaceholder: 'Enter Days you need to ready the order'
    })

    if (days) {
      firebase.database().ref("/orders").child(item.id).set({ ...item, status: "accepted", estimatedDays: days }).then(() => {
        firebase.database().ref("/users").child(item.userId).child("orders").child(item.id).set({ ...item, status: "accepted", estimatedDays: days }).then(() => {
          firebase.database().ref("/users").child(user.userData.userId).child("orders_got").child(item.id).set({ ...item, status: "accepted", estimatedDays: days }).then(() => {
            window.location.reload()
          })
        })
      })
    }
  }
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{item.name}</td>
        <td>{item.quantity}</td>
        <td>
          {buyerData.block}, <br />
          {buyerData.district}, <br />
          {buyerData.state}
        </td>
        <td>Rs: {item.price} x {item.quantity}</td>
        {
          item.status === "accepted" ? <>
            <td>
              Status: Accepted
            </td>
          </> : <> <td align='center'><button onClick={refuse} className="btn btn-danger">Refuse</button></td>
            <td align='center'><button onClick={accept} className="btn btn-success">Accept</button></td></>
        }
      </tr>

    </>
  )
}
export default SellerPlacedOrders