import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import { useDataLayerValue } from './DataLayer/DataLayer';
import firebase from './firebase';
import AboutPage from './Pages/about';
import Categories from './Pages/Categories';
import Category from './Pages/category';
import ProductPage from './Pages/category/product';
import FilteredProductList from './Pages/category/product/List';
import AddItemToStore from './Pages/common/AddItemToStore';
import Notifications from './Pages/common/Notifications';
import SellerNav from './Pages/common/SellerNav';
import SellerPlacedOrders from './Pages/common/SellerPlacedOrders';
import TalkToExpert from './Pages/common/TalkToExpert';
import Cart from './Pages/cart';
import LoginPage from './Pages/common_users/LoginPage';
import Orders from './Pages/common_users/Orders';
import PlaceOrder from './Pages/common_users/PlaceOrder';
import Profile from './Pages/profile';
import SellerStore from './Pages/common_users/SellerStore';
import ContactPage from './Pages/contact';
import CorporateProfileCreate from './Pages/corporate/CorporateProfileCreate';
import EnquiryForm from './Pages/EnqueryForm';
import FarmerDataForm from './Pages/farmer/FarmerDataFrom';
import HomePage from './Pages/HomePage';
import FilteredByState from './Pages/products/FilteredByStates';
import FilteredByBlocks from './Pages/products/ProductNearBy';
import Search from './Pages/search';
import RegistrationPage from './Pages/RegistrationPage';
import SellerProfile from './Pages/seller';
import PrivacyPolicy from './Pages/user_aggreements/PrivacyPolicy';
import Terms from './Pages/user_aggreements/Terms';
import './style/global.css';
import CorporatesProfile from './Pages/seller/corporate';
import Address from './Pages/profile/address';
/*eslint-disable */
function App() {
    const [{ authInitilize }, dispatch] = useDataLayerValue()
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const loadData = () => {
            firebase.database().ref('categories/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const rowCateArr = [];

                for (let id in snapVal) {
                    rowCateArr.push({ id, ...snapVal[id] })
                }

                // setCategory(rowCateArr)
                dispatch({
                    type: 'SET_CATE_LIST',
                    data: rowCateArr
                })

            })
            firebase.database().ref('items/').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const rawItemsList = [];

                for (let id in snapVal) {
                    var curruntItem = snapVal[id]
                    var newObj = {
                        ...curruntItem,
                        price: curruntItem.userType === "corporate" ? curruntItem.price : (((parseInt(curruntItem.price)) + (parseInt(curruntItem.price) * (5 / 100)))).toFixed(0)
                    }
                    rawItemsList.push({ ...newObj, id })
                }


                dispatch({
                    type: "SET_PRODUCTS",
                    data: rawItemsList
                })
            })



        }
        loadData()
        return () => {
            loadData()
        }
    }, [])
    useEffect(() => {

        const loadData = () => {

            firebase.database().ref('/item-to-verify').on('value', (snapshot) => {
                var snapVal = snapshot.val();
                const rawToVerifyList = [];
                for (let id in snapVal) {
                    rawToVerifyList.push({ id, ...snapVal[id] })
                }
                dispatch({
                    type: "SET_ITEM_TO_VERIFY",
                    data: rawToVerifyList
                })

            })
        }
        loadData()
        return () => {
            loadData()
        }

    }, [])

    useEffect(() => {
        firebase.auth().onAuthStateChanged((currentUser) => {
            if (currentUser) {
                firebase.database().ref('users/').child(currentUser.uid).on('value', (snapshot) => {
                    const rawUserData = snapshot.val();
                    dispatch({
                        type: "SET_USER_DATA",
                        data: rawUserData
                    })
                })
                dispatch({
                    type: "SET_USER",
                    data: { uid: firebase.auth().currentUser.uid, phoneNumber: firebase.auth().currentUser.phoneNumber }
                })
                dispatch({
                    type: "SET_INITIALLIZE",
                    data: false
                })
            }
            else {
                dispatch({
                    type: "SET_USER",
                    data: null
                })
                dispatch({
                    type: "SET_USER_DATA",
                    data: null
                })
                dispatch({
                    type: "SET_INITIALLIZE",
                    data: false
                })
            }
        })

    }, [])
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/address" element={<Address />} />
            <Route path="/my-store" element={<SellerNav />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/place-order" element={<PlaceOrder />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/my-store/shop" element={<SellerStore />} />
            <Route path="farmer/add-item-to-store" element={<AddItemToStore />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/corporate/add-item-to-store" element={<AddItemToStore />} />
            <Route path="products/block/:block" element={<FilteredByBlocks />} />
            <Route path="/products/state/:state" element={<FilteredByState />} />
            <Route path="/product-enquiry" element={<EnquiryForm />} />
            <Route path='/talk-to-expert' element={<TalkToExpert />} />
            <Route path='/notifications' element={<Notifications />} />
            <Route path='/search' element={<Search />} />




            <Route path="/my-store/placed-orders" element={<SellerPlacedOrders />} />

            {/* Products Route */}
            <Route path='/categories' element={<Categories />} />
            <Route path="/category/:category" element={<Category />} />
            <Route path="/category/:category/:product" element={<FilteredProductList />} />
            <Route path="/category/:category/:product/:itemId" element={<ProductPage />} />

            {/* Seller's Router */}
            <Route path="seller/:sellerName/:sellerId" element={<SellerProfile />} />
            <Route path="seller/:sellerName" element={<CorporatesProfile />} />
            {/* corporate profile
             */}
            <Route path="/corporate/create-profile" element={<CorporateProfileCreate />} />


            <Route path="/farmer/create-profile" element={<FarmerDataForm />} />
            <Route
                path="*"
                element={<Navigate to="/" replace />}
            />
        </Routes>
    );


}
/*eslint-enable */
export default App;
