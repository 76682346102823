import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Footer from "../../../Component/Footer"
import Loading from '../../../Component/Common/Loading'
import { useDataLayerValue } from '../../../DataLayer/DataLayer'
import { firebaseAnalytics } from '../../../firebase'
import "../../../style/product-page.css"
import ProductCard from '../../../Component/Products/ProductCard'
import User from '../../../DataLayer/User'
import addToCart from '../../../util/products/addToCart'
import Header from '../../../Component/Common/Header/Header'
import slugify from '../../../util/common/slugify'
import fetchProductData from '../../../util/products/fetchProductData'
/*eslint-disable */

function ProductPage() {
  const [{ products }] = useDataLayerValue()
  const [productData, setProductData] = useState(null)
  const productId = useLocation().pathname.split("/")[4]
  useEffect(() => {
    const loadData = async () => {
      var productData = await fetchProductData(productId)
      setProductData(productData)
    }
    loadData();
  }, [])
  if (productData) {
    return (
      <>
        <Header />
        <br /><br /> <br />
        <div className="wrapper product-page">
          <ProductDetails itemData={productData} />
        </div>
        <div className="container">
          <br /><br />
          <h3>
            Similar Products
          </h3>
          <div className="card-wrap">
            {products.map((itemData, index) => {
              if (itemData.id === productId) {
                return (
                  <>
                    {products.map((item, index) => {
                      if (itemData.category === item.category && itemData.id !== item.id) {

                        return (
                          <ProductCard key={index} data={{...item}} />
                        )
                      }
                    })}
                  </>
                )
              }
            })}
          </div>
        </div>
        <Footer />
      </>
    )
  }
  else {
    return <Loading />
  }
}
const ProductDetails = (props) => {
  const [{ user }, dispatch] = useDataLayerValue()
  const navigate = useNavigate()
  const itemData = props.itemData
  useEffect(() => {

    firebaseAnalytics.logEvent(`${itemData.name}_visited`)

  }, [])
  const userObj = new User(user)
  const cartProps = {
    user: userObj,
    curruntUser: user,
    item: itemData, navigate
  }
  const sellerSlug = () => {

    if (itemData.userType === "corporate") {
      return `/seller/${slugify(itemData.sellerName)}`
    }
    else {
      return `/seller/${slugify(itemData.sellerName)}/${itemData.sellerUID}`
    }
  }
  const price = () => {
    if (itemData.unit === "piece") {
      return itemData.price
    }
    else {
      return `${itemData.price} / ${itemData.unit}`
    }
  }
  return (
    <div>
      <meta property='product:price:amount' content={price()} />
      <div className='wrapper product-page'>
        <div className="product image-box">
          <img loading='lazy' id="pro-image" src={itemData.imgUrl} />
        </div>
        <div className="product details">
          <h3 className="d-flex justify-content-between"><span><Link to={`/category/${slugify(itemData.category)}/${slugify(itemData.name)}`}>{itemData.name}</Link></span>
            <div className="icon-box">
              {/* <a className="share-whatsapp" href={`whatsapp://send?text=Buy *${itemData.name}* from https://${window.location.hostname}${window.location.pathname}`}data-action="share/whatsapp/share"
              target="_blank"> <i className="fa-brands fa-whatsapp"></i></a> */}
              <a className="share-whatsapp" href={`whatsapp://send?text=Buy *${itemData.name}* from https://link.ekisandarshan.in${window.location.pathname}`} data-action="share/whatsapp/share"
                target="_blank"> <i className="fa-brands fa-whatsapp"></i></a>
            </div>
          </h3>
          <h4 className="price">&#8377;  {price()}</h4>
          <h3 className="pro-desc"><i className="fa-solid fa-map-marker-alt"></i>  {itemData.block ? <Link to={`/products/block/${itemData.block}`}>{itemData.block}</Link> : itemData.district}, <Link to={`/products/state/${itemData.state}`}>{itemData.state}</Link></h3>

          <span><b>Seller Name: </b><Link to={sellerSlug()}>{itemData.sellerName}</Link></span>
          <span className='text-capitalize'><b>Sold By: </b>{itemData.userType}</span>
          {
            !itemData.variety ? "" : <span><b>Variety: </b>{itemData.variety}</span>
          }
          <button onClick={addToCart(cartProps)} className="btn btn-width btn-success"><i className="fa-solid fa-shopping-cart"></i> Add to cart</button>
        </div >
      </div>
      {
        !itemData.description ? "" : <span><b>Description: </b>{itemData.description}</span>
      }
    </div>
  )
}

/*eslint-enable */
export default ProductPage