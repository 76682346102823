
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Loading from '../../Component/Common/Loading';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import firebase from '../../firebase';
import '../../style/registration.css'
import Header from '../../Component/Common/Header/Header';
function CorporateProfileCreate() {
    const [{ user }] = useDataLayerValue()
    const [formData, setFormData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 2000)
    }, [])

    let name, value;
    const userAuthData = user.userAuthData;
    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }
    const createCooperateAccount = (e) => {
        e.preventDefault()
        firebase.database().ref('users').child(user.userAuthData.uid).child("corporateData").set({ ...formData, lastUpdate: Date.now() }).then(() => {
            Swal.fire("Profile Created Successfully!", '', 'success').then(() => { window.location.replace('/profile') })
        })
    }


    if (isLoading === true) {
        return <Loading />
    }
    else { }
    if (userAuthData !== null) {
        if (user.userData !== null) {
            if (user.userData.userType === "corporate") {
                if (user.userData.corporateData === undefined) {
                    return (
                        <>
                            <Header />
                            <div className="registration">
                                <form onSubmit={createCooperateAccount}>
                                    <div id="wrapper">
                                        <h4>Complete Your Profile</h4>
                                        <div>
                                            <input onChange={handleFormChanges} required className="inputs-reg" type="text" name="name" id="name" placeholder="Company Name" />
                                        </div>
                                        <select onChange={handleFormChanges} required className="inputs-reg" name="company_type" id="company-type">
                                            <option value="">Company Type</option>
                                            <option value="Manufacturer">Manufacturer</option>
                                            <option value="Seller-Vendors-Distributor">Seller/Vendors/Distributor</option>
                                            <option value="FPO-SHG">FPO (Farmer Producer Orgnisation)/SHG (Self Help Group)</option>
                                        </select>
                                        <div>
                                            <input onChange={handleFormChanges} required className="inputs-reg" type="text" name="contact_person_name" id="contact-person-name"
                                                placeholder="Contact Person Name" />
                                        </div>
                                        <div>
                                            <input onChange={handleFormChanges} required className="inputs-reg" type="text" name="contact_person_email" id="contact-person-email"
                                                placeholder="Contact Person Email" />
                                        </div>
                                        <div>
                                            <input onChange={handleFormChanges} required className="inputs-reg" type="phone " max="9999999999" min="1000000000" maxLength="10" minLength="10" name="contact_person_phone"
                                                id="contact-person-phone" placeholder="Contact Person Phone" />
                                        </div>
                                        <div>
                                            <input onChange={handleFormChanges} required className="inputs-reg" type="text" name="address" id="address"
                                                placeholder="Company Address (Please provide full address)" />
                                            <br />
                                            <div>
                                                <input onChange={handleFormChanges} maxLength={14} className="inputs-reg" type="number" name="gst" id="gst" placeholder="GST IN (optional)" />
                                            </div>
                                            <div>
                                                <input onChange={handleFormChanges} className="inputs-reg" type="text" name="iso" id="iso"
                                                    placeholder="ISO Certification Number (optional)" />
                                            </div>
                                            <div>
                                                <input onChange={handleFormChanges} className="inputs-reg" type="text" name="website" id="website"
                                                    placeholder="Company Website (optional)" />
                                            </div>

                                        </div>
                                        <div className="agree">
                                            <input required type="checkbox" name="agree" id="agree" />
                                            <label for="agree">
                                                <p>
                                                I agree to the terms and condition mentioned in privacy policy and agreement statement
                                                </p>
                                            </label>
                                        </div>
                      



                                        {/* <StateDisctrict formData={formData} handleFormChanges={handleFormChanges} /> */}
                                        <br />
                                        <input className="theme-btn" value="Submit" id="submit" type="submit" />
                                        <br /><br />
                                    </div>
                                </form>
                            </div>
                        </>
                    )
                }
                else {
                    window.location.replace("/profile")
                }
            }
            else {
                window.location.replace("/")
            }
        }
        else {
            window.location.replace("/home")
        }
    }
    else {
        window.location.replace("/login")
    }
}

export default CorporateProfileCreate