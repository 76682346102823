import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import MyCarousel from '../Component/Carousel'
import Header from "../Component/Common/Header/Header"
import Loading from '../Component/Common/Loading'
import Footer from '../Component/Footer'
import Consumer from '../Component/HomePage/Consumer'
import Corporate from '../Component/HomePage/Corporate'
import Farmer from '../Component/HomePage/Farmer'
import { useDataLayerValue } from '../DataLayer/DataLayer'
import { firebaseAnalytics } from '../firebase'
import '../style/home.css'


function HomePage() {
    const [{ products, authInitilize }] = useDataLayerValue()
    useEffect(() => {
        firebaseAnalytics.logEvent('home_page_visited')
    }, [])

    if (authInitilize) {
        return <Loading />
    }
    else {
        return (
            <>
                <Header />
                <MyCarousel />
                <div className="d-flex mt-3 justify-content-center">
                    <Link to="/product-enquiry">
                        <button className='btn btn-width btn-primary'>
                            Product Query
                        </button>
                    </Link>
                </div>
                <div className="Home">
                    <div className="container">
                        <DisplayCriteria />
                    </div>
                    <div className="call-us">
                        <div className="call-img">
                            <img
                                src="https://images.unsplash.com/photo-1626863905121-3b0c0ed7b94c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y3VzdG9tZXIlMjBzZXJ2aWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                                loading="lazy"
                                alt="Customer Care Executive"
                            />
                        </div>
                        <div className="call-para">
                            <h3>Need Help? Talk with us</h3>
                            <p>
                                If you have any Query feel free <br /> to call
                                <a href="tel:+919131248957"> us on +919131248957 </a> <br /> or Mail <a href="mailto:info@ekisandarshan.in"> us on info@ekisandarshan.in</a><br />
                                <Link to="/contact"> You can also Leave a Messege here</Link>
                            </p>
                        </div>
                    </div>
                    <Footer />
                </div>

            </>

        )
    }
}
const DisplayCriteria = () => {
    const [{ user, authInitilize }] = useDataLayerValue()
    const userData = user.userData
    if (authInitilize) { return <Loading /> }
    else if (userData === null) {
        return (
            <Consumer />
        )
    }
    else if(userData === undefined){
        return <Loading />
    }
    else if (userData.userType === "farmer") {
        return (
            <Farmer />
        )
    }
    else if (userData.userType === "corporate") {
        return (
            <Corporate />
        )
    }
    else {
        return (
            <Consumer />
        )
    }

}
export default HomePage
