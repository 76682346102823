import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Header from '../../Component/Common/Header/Header';
import Loading from '../../Component/Common/Loading';
import ProfileComon from '../../Component/ProfileComon';
import StateDisctrictEdit from '../../Component/StateDisctrictEdit';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import firebase from '../../firebase';
import '../../style/Profile.css';
/*eslint-disable */
export default function Address() {
    const [{ user, authInitilize }] = useDataLayerValue()

    if (authInitilize || user.userData === undefined) {
        return <Loading />
    }
    else {
        if (user.userAuthData === null) {
            window.location.replace('/login')
        }
        if (user.userData === null) {
            window.location.replace('/register')
        }
        return (
            <div className="profile">
                <Header />
                <div style={{ margin: "80px 0" }}>
                    <div className="row profile">
                        <ProfileComon />
                        <div className="col-md-8">
                            <AddressForm />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const AddressForm = () => {
    const [{ user }] = useDataLayerValue()
    const userData = user.userData

    const [formData, setFormData] = useState({
        address_line_1: userData.address_line_1,
        address_line_2: userData.address_line_2,
        pincode: userData.pincode,
        district: userData.district,
        state: userData.state,
    })
    let name, value;
    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }
    const updateProfile = (e) => {
        e.preventDefault()
        delete formData.phone
        if (!formData.email) {
            delete formData.email
        }
        firebase.database().ref('users/').child(userData.userId).set({ ...userData, ...formData }).then(() => {
            Swal.fire("Your profile has update successfully!", "", "success")
        })
    }
    return (
        <form onSubmit={updateProfile} className="container profile-page-pro rounded bg-white  mb-2">

            <div >
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text">Address Settings</h4>
                </div>
                <div className="form-group">
                    <label className="labels" htmlFor="addressline1">Address Line 1</label>
                    <input type="text" onChange={handleFormChanges} name="address_line_1" value={formData.address_line_1} placeholder='House No, Building Name' id="addressline1" className='form-control' />
                    <label className="labels" htmlFor="addressline2">Address Line 2</label>
                    <input type="text" onChange={handleFormChanges} name="address_line_2" value={formData.address_line_2} placeholder='Area, Sector, City' id="addressline2" className='form-control' />
                    <label className="labels" htmlFor="PIN">PIN Code</label>
                    <input type="text" onChange={handleFormChanges} name="pincode" value={formData.pincode} placeholder='eg: 801110' id="PIN" className='form-control' />
                </div>
                <div className="row">
                    <StateDisctrictEdit handleFormChanges={handleFormChanges} setFormData={setFormData} formData={formData} />
                </div>
                <div className="mt-5 text-center">
                    <button className="btn btn-width btn-primary profile-button" type="submit">
                        Update Address
                    </button>
                </div>
            </div>
        </form>
    )
}