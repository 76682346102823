import React from 'react';
import { Link } from 'react-router-dom';
import { useDataLayerValue } from '../../../../DataLayer/DataLayer';
import "../../../../style/kd-index.css";
import { objectLength } from '../../../../util/common/functions';
import '../../../../style/navbar.css';
const DeskNav = () => {
    const [{ user }] = useDataLayerValue()
    const userAuth = user.userAuthData;
    const userData = user.userData;

    if (userData === undefined || userAuth === undefined || userData === null) {
        return null
    }
    else {
        if (user.userData.userType === "farmer") {
            return (
                <div className="ex-nav">
                    <nav>
                        {
                            userData.farmerData && objectLength(userData.farmerData) !== 0 ?
                                <nav className="more-options my-store-opt">
                                    <Link to="/my-store">
                                        <button className='btn btn-success p-0 px-3 ml-5 cat-button'>
                                            My Store
                                        </button>
                                    </Link>
                                </nav>
                                :
                                <><Link to="/profile"><button className="btn p-2 ml-4 my-store-button btn-danger"><i className="fa-solid fa-store-slash"></i>Store is locked</button></Link></>

                        }

                    </nav>
                </div>
            )
        }
        else if (user.userData.userType === "corporate") {
            return (
                <div className="ex-nav">
                    <nav>

                        {
                            userData.corporateData && objectLength(userData.corporateData) !== 0 ?
                                <nav className="more-options my-store-opt">
                                    <Link to="/my-store">
                                        <button className='btn btn-success p-2 ml-5 cat-button'>
                                            My Store
                                        </button>
                                    </Link>
                                </nav>
                                :
                                <><Link to="/profile"><button className="btn p-2 ml-4 btn-width my-store-button btn-danger"><i className="fa-solid fa-store-slash"></i>Store is locked</button></Link></>

                        }

                    </nav>
                </div>
            )
        }

    }

}
export default DeskNav