import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Header/Header'
import Footer from '../../Footer'

function InfoPage({ children, title, desc, href }) {
    return (
        <>
            <Header />
            <div className='info-page'>
                <i className="fa-solid fa-lock">

                </i>
                <h3>
                    {title}
                </h3>
                <p>
                    {desc}
                </p>
                <Link to={href}>
                    <button className="btn btn-success">
                        Continue
                    </button>
                </Link>
            </div>
            <Footer />
        </>
    )
}

export default InfoPage