import React from 'react'
import BreadBgImg from "../assets/images/breadcrumb-bg.webp"
import BussinesWithUS from "../assets/images/bussinessWithUs.jpg"
import Dhiraj from '../assets/images/dhiraj.jpg'
import Farmer1 from "../assets/images/farmer1.png"
import Farmer2 from "../assets/images/farmer2.png"
import Farmer4 from "../assets/images/farmer4.png"
import fruits from "../assets/images/fruits.png"
import Himanshu from '../assets/images/himanshu.jpg'
import machin from "../assets/images/machin.png"
import oilseeds from "../assets/images/oilseeds.png"
import Praveen from '../assets/images/praveen.jpg'
import pulses from "../assets/images/pulses.png"
import spices from "../assets/images/spices.png"
import TeamBg from "../assets/images/teamBg.jpg"
import veg from "../assets/images/veg.png"
import Footer from '../Component/Footer'
import Header from "../Component/Common/Header/Header"
import "../style/about.css"
import "../style/home.css"
import "../style/templatemo-style.css"

const OurTeamBg = {
  backgroundImage: "url(" + TeamBg + ")"
};

const Breadbg = {
  backgroundImage: "url(" + BreadBgImg + ")"
};

export default function AboutPage() {
  return (
    <>
      <Header />
      <main className="aboutus">
        {/* breadcrumb */}
        <section className="bread-bg px-auto pb-5" style={Breadbg}>
          <h1 className="bread-title">About us</h1>
        </section>
        {/* breadcrumb ends */}
        {/* about us part*/}
        <div className="section py-5">
          <div className="container">
            <h1>
              <center className="main-title text-center text-success pb-2 text-success border-success border border-5 px-5">
                eKisan Darshan
              </center>
            </h1>
            <br />
            <br />
            <br />
            <br />
            <div className="row">
              <div className="col-lg-4 col-sm-10">
                <img src={Farmer2} className="rounded" alt="" />
                <br />
                <br />
                <br />
              </div>
              <div className="col-lg-8 col-sm-12 px-4">
                <h1 className="text-success pt-4 border-bottom border-success border-2 pb-2">
                  Why Choose us?
                </h1>
                <br />
                <h3>Get a Virtual Store</h3>
                <p>
                  eKisan Darshan a platform for farmers, wholesaler, bulk
                  buyers, and daily consumers to bring all in one agricultural
                  market.
                </p>
                <h3>Show your Products</h3>
                <p>
                  eKisan Darshan helps farmer showcase their resources,
                  agricultural activities and sell farm produce to consumers and
                  corporate directly which brings the overall cost within the
                  range for all.
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-10 order-lg-2 px-4">
                <img src={Farmer1} className="rounded" alt="" />
                <br />
                <br />
                <br />
              </div>
              <div className="col-lg-8 col-sm-12 order-lg-1 px-4">
                <h1 className="text-success pt-4 border-bottom border-success border-2 pb-2">
                  What you Get?
                </h1>
                <br />
                <br />
                <h3>Connect Seamlessly</h3>
                <p>
                  eKisan Darshan also helps in connecting industry experts, market
                  and farmers which will result in balancing market demand and
                  farm production.
                </p>

                <h3>Some addons we provide</h3>
                <p>
                  Safety, hygiene and punctual delivery of fresh products are our
                  responsibility.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* about us part ends */}

        {/* imgdivider */}

        <section className="py-5">
          <img src={Farmer4} alt="img-divider" />
        </section>

        {/* imgdivider ends here */}

        {/* bussines with us here */}

        <div className="container pt-5 my-5">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <img src={BussinesWithUS} alt="" className="px-3" />
              <br />
              <br />
              <br />
            </div>
            <div className="col-lg-6 col-sm-12 px-4">
              <h1 className="text-success border-bottom border-success border-5 pb-2 text-center">
                Bussines with us
              </h1>
              <br />
              <ul>
                <h3>We can be parter in mutual growth</h3>
                <br />
                <li style={{ textAlign: "justify" }}>
                  We provide localized system that helps the small vendors or
                  sellers of all kinds of agricultural inputs (farm implements,
                  fertilizers, seeds, pesticides etc.) and produce (vegetables,
                  fruits, whole food grains etc.) to reach out to farmers as well
                  as consumers in your own region. eKisan Darshan Get a virtual
                  store.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Bulk buyers and sellers can have a real time raw material
                  availability region wise.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Food processing organizations can have data like fresh raw
                  materials availability both season and region wise.
                </li>
                <li style={{ textAlign: "justify" }}>
                  The system can help in establishing localized big planned
                  cultivation as the farmer’s available resources can be utilized
                  in better ways as can be clubbed also.
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* bussines with us ends here */}
        {/* team */}
        <section style={OurTeamBg}>
          <div className="container py-5">
            <h1 className="pb-2 text-success border-bottom border-success border-5 text-center">
              Our Team
            </h1>
            <div className="row pt-5 my-3">
              <div className="col-lg-8 col-sm-12">
                <div className="row">
                  <div className="col-6">
                    <img
                      className="border-success border border-2 rounded"
                      src={Praveen}
                      alt=""
                    />
                  </div>
                  <div className="col-6">
                    <h3 className="text-success">Shri. Praveen Sharma</h3>
                    <h3 className="text-success">श्री. प्रवीण शर्मा</h3>
                    <p>Owner and Founder</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="row my-3">
                  <div className="col-6">
                    <img
                      className="border-success border border-2 rounded"
                      src={Himanshu}
                      alt=""
                    />
                  </div>
                  <div className="col-6">
                    <h3 className="text-success">Himanshu Bari</h3>
                    <h3 className="text-success">हिमांशु बारी</h3>
                    <p>Frontend Developer and R&amp;D</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="row my-3">
                  <div className="col-6">
                    <img
                      className="border-success border border-2 rounded"
                      src={Dhiraj}
                      alt=""
                    />
                  </div>
                  <div className="col-6">
                    <h3 className="text-success">Dhiraj Prajapati</h3>
                    <h3 className="text-success">धीरज प्रजापति</h3>
                    <p>Full Stack Developer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* team ends here */}
        {/* products */}
        <div className="section py-5">
          <div className="container">
            <h1 className="text-success pb-2 text-success border-bottom border-success border-5 text-center">
              What you can Buy or Sell
            </h1>
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="inner">
                  <img src={fruits} alt="" />
                  <h3 className="text-center">Fruits</h3>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="inner">
                  <img src={veg} alt="" />
                  <h3 className="text-center">Vegetables</h3>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="inner">
                  <img src={oilseeds} alt="" />
                  <h3 className="text-center">Oilseeds</h3>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="inner">
                  <img src={pulses} alt="" />
                  <h3 className="text-center">Pulses</h3>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="inner">
                  <img src={spices} alt="" />
                  <h3 className="text-center">Spices</h3>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="inner">
                  <img src={machin} alt="" />
                  <h3 className="text-center">Machineries</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

