import React from 'react'
import FarmerProfileForm from './Farmer'
import CorporateProfileForm from './Corporate'
import { useDataLayerValue } from '../../../DataLayer/DataLayer'
import { Link } from 'react-router-dom'
const SellerAccount = () => {
    const [{ user }] = useDataLayerValue()
    const userData = user.userData
    if (userData.userType === "farmer") {
        return <FarmerProfileSetting />
    }
    else if (userData.userType === "corporate") {
        return <CorporateProfileForm />
    }
}
const FarmerProfileSetting = () => {

    const [{ user }] = useDataLayerValue()
    const userData = user.userData
    if (userData.userType === "farmer") {
        if (user.userData.farmerData) {
            return <FarmerProfileForm />
        }
        else {
            return <div className='d-flex justify-content-center flex-column align-items-center'>
                <h4>Create your Profile to unlock your store</h4>
                <Link to="/farmer/create-profile"><button className="btn btn-width btn-success">Create your Farmer Profile - अपना प्रोफाइल बनाएं</button></Link>
            </div>
        }
    }

}
export default SellerAccount