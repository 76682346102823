import Footer from "../../Component/Footer";
import Header from "../../Component/Common/Header/Header"
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import React, { useState } from "react"
import { Link } from "react-router-dom";

import { BsSearch } from "react-icons/bs";
import slugify from "../../util/common/slugify";

const Search = () => {
    const [{ category, products }] = useDataLayerValue()
    const [text, setText] = useState("")
    const [resultList, setResultList] = useState(products)
    const handleChange = (event) => {
        const value = event.target.value;

        const criteria = (item) => {
            var txt = value?.toLowerCase()

            return (
                item.name?.toLowerCase().includes(txt)
                ||
                item.category?.toLowerCase().includes(txt)
                ||
                item.block && item.block?.toLowerCase().includes(txt)
                ||
                item.state?.toLowerCase().includes(txt)
                ||
                item.district?.toLowerCase().includes(txt)
                ||
                item.sellerName?.toLowerCase().includes(txt)
            )
        }
        var resultRaw = products.filter((item) => criteria(item))
        setResultList(resultRaw)
        setText(value)
    }
    return (
        <>
            <Header />
            <section className="px-lg-5" style={{ marginTop: 60, }}>
                <div className="container px-lg-5 py-2">
                    <div className="form-group search-bar">
                        <label>
                            <BsSearch  />
                        </label>
                        <input type="text" onChange={handleChange}  placeholder="Search Product/Farmer/Seller/Location" className="form-control" />
                    </div>
                    <div className="row">
                        {
                            text === "" ? <Deafult />
                                :
                                resultList.length ?
                                    <>
                                        {
                                            resultList.map((item, index) => {
                                                return (
                                                    <Link to={`/category/${slugify(item.category)}/${slugify(item.name)}/${item.id}`} className="outer col-12 col-lg-6">
                                                        <div className="search-item ">
                                                            <div className="img-div">
                                                                <img loading='lazy' src={item.imgUrl} />
                                                            </div>
                                                            <div className="detail">
                                                                <h4>{item.name}</h4>
                                                                <h6>{item.category}</h6>
                                                                <h6>{item.block ? item.block : item.district}, {item.state}</h6>
                                                                <h6>{item.sellerName} </h6>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <h1>No result found</h1>
                        }
                    </div>

                </div>
            </section>
            <Footer />
        </>
    );
};
const Deafult = () => {
    const [{ category }] = useDataLayerValue()
    return (
        <div className="row" >
            {
                category.map((item, index) => {
                    return (
                        <Link style={{ textDecoration: "none" }} className="col-4 col-lg-2 text-center" to={`/store/${item.categorieName}`} >
                            <div >
                                <div className="my-1 mx-0 border rounded-2 hover-shadow pb-1">
                                    <img loading='lazy' src={item.coverUrl} className="img-fluid rounded-circle p-lg-4 p-2" alt="" />{" "}
                                    <p>{item.categorieName}</p>
                                </div>
                            </div>
                        </Link>
                    )
                })
            }
        </div >
    )
}
export default Search;
