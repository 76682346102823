import React from 'react'
import { useDataLayerValue } from '../DataLayer/DataLayer'
import { BsPencilSquare } from "react-icons/bs"
import Swal from 'sweetalert2'
import firebase from "../firebase"
import { imgExtRemover } from '../util/common/imgExtRemover'
import Loading from './Common/Loading'
import { useState } from 'react'
import slugify from '../util/common/slugify'
import { useEffect } from 'react'
function ProfileComon() {
    const [{ user }] = useDataLayerValue()
    const userData = user.userData
    return (
        <div className="col-md-4 mb-3">
            {
                userData.userType === "corporate" && userData.corporateData ?
                    <CorporateProfile />
                    :
                    <>
                        <div className="d-flex flex-column align-items-center text-center">
                            <img className="rounded-circle" width="150px" alt="logo" src="/images/user.png" />
                            <h5 className='text-capitalize'>{userData.name}</h5>
                        </div>
                    </>
            }

        </div>
    )



}
const CorporateProfile = () => {
    const [{ user }] = useDataLayerValue()
    const [isUploading, setIsUploading] = useState(false)
    const [corporate, setCorporate] = useState([])
    const userData = user.userData
    useEffect(() => {
        firebase.database().ref('corporates/').child(slugify(userData.corporateData.name)).once("value").then((snapshot) => {
            var SnapVal = snapshot.val()
            setCorporate(SnapVal)
        })
    })
    const uploadLogo = ({ TIME_STEMP, image }) => {
        firebase.storage().ref("corporate-logos/").child(`${TIME_STEMP}.${imgExtRemover(image.name)}`).put(image).on(
            "state_changed",
            () => {
                setIsUploading(true)
            },
            (error) => {

            },
            () => {
                firebase.storage().ref("corporate-logos/").child(`${TIME_STEMP}.${imgExtRemover(image.name)}`).getDownloadURL().then((url) => {
                    firebase.database().ref("corporates").child(slugify(userData.corporateData.name)).child("logo").set(url).then(() => {
                        setIsUploading(false)
                    })
                })
            }
        )
    }
    const handleChange = (e) => {
        const image = e.target.files[0]
        const TIME_STEMP = Date.now()
        if (image.size > 500000) {
            Swal.fire("Image is to big", "Image size should be smaller than 500KB", "error")
        }
        else {
            if (corporate.logo) {
                var imageName = corporate.logo.split("%2F")[1].split("?")[0]
                firebase.storage().ref("corporate-logos").child(imageName).delete().then(() => {
                    uploadLogo({ image, TIME_STEMP })
                })
            }
            else {
                uploadLogo({ TIME_STEMP, image })
            }
        }
    }
    if (userData.corporateData) {
        return (
            <div className="d-flex flex-column align-items-center text-center pt-5">
                {
                    isUploading &&
                    <Loading zIndex={9} />
                }
                <div className="circle">
                    <label className="edit-label" htmlFor="logo">
                        <div className="edit-icon">
                            <BsPencilSquare />
                        </div>
                    </label>
                    <img className="rounded-circle" width="150px" alt="logo" src={corporate.logo ? corporate.logo : `/images/profile/corporate.svg`} />
                </div>
                <input onChange={handleChange} className='d-none' type="file" id="logo" />
                <h5 className='text-capitalize mt-4'>{userData.corporateData.name}</h5>
            </div>
        )
    } else {
        return (
            <div className="d-flex flex-column align-items-center text-center pt-5">
                <img className="rounded-circle" width="150px" alt="logo" src="/images/user.png" />
            </div>
        )
    }

}
export default ProfileComon