import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Header from "../../Component/Common/Header/Header"
import InfoPage from '../../Component/Common/UI Components/InfoPage'
import Loading from '../../Component/Common/Loading'
import { useDataLayerValue } from '../../DataLayer/DataLayer'
import firebase from "../../firebase"
function SellerStore() {
  const [{ user, itemToVerify, authInitilize, products }] = useDataLayerValue()
  const [isEditing, setIsEditing] = useState(false)
  const [editingData, setEditingData] = useState({})
  const navigate = useNavigate()
  const removeItem = (e, id, imgUrl) => {
    switch (e) {
      case "queue":
        var imageName = imgUrl.split("%2F")[1].split("?")[0]
        firebase.storage().ref("items").child(imageName).delete().then(() => {
          firebase.database().ref('item-to-verify').child(id).remove().then(() => {
            Swal.fire("Item remove successfully")
          })
        })

        break
      case "fromStore":
        Swal.fire(
          'Do you really want to delelte these item?',
          '',
          'warning'
        ).then(() => {
          var imageName = imgUrl.split("%2F")[1].split("?")[0]

          firebase.storage().ref("items").child(imageName).delete().then(() => {
            const ItemRef = firebase.database().ref('items/').child(id);
            ItemRef.remove().then(() => {
              Swal.fire(
                'Deleted Successfull',
                "",
                'success'
              )
            })
          })




        })
        break
      default:
    }
  }
  const editItem = (item) => {
    setEditingData(item)
    setIsEditing(true)
  }
  if (authInitilize || user.userData === undefined) {
    return <Loading />
  }
  else if (user.userData === null) {

  }
  else if (user.userData.userType === "farmer" || user.userData.userType === "corporate") {
    if (user.userData.farmerData || user.userData.corporateData) {
      const sellerProductsInQueue = []
      itemToVerify.map(
        // eslint-disable-next-line
        (item) => {
          if (item.sellerUID === user.userAuthData.uid) {
            sellerProductsInQueue.push({ ...sellerProductsInQueue, item })
          }
        }
      )
      const sellerProductVerified = []
      products.map(
        // eslint-disable-next-line
        (item) => {
          if (item.sellerUID === user.userAuthData.uid) {
            sellerProductVerified.push({ ...sellerProductVerified, item })
          }
        }
      )
      const sellerProductRejected = [];
      for (let id in user.userData.item_rejected) {
        sellerProductRejected.push({ id, ...user.userData.item_rejected[id] })
      }

      if (user.userData.product_to_sell === undefined) {
        return (
          <>
            <Header />
            <br />  <br /> <br />
            <div className="container farmer-store mt-5">
              <h5>Items in queue for verification - सत्यापन के लिए आपका उत्पाद कतार में</h5>
              <div className="card-wrap">
                {sellerProductsInQueue.length ? sellerProductsInQueue.map((itemProp, index) => {
                  var item = itemProp.item
                  return (
                    <div className="product">

                      <div className="img-box-pro">
                        <img src={item.imgUrl} loading='lazy' alt="wheat" className="pro-img" />
                        {
                          item.organic === "yes" && <><div className="green-box"></div></>
                        }
                      </div>
                      <div className="pro-detail">
                        <h3 className="pro-name">{item.cropName}</h3>
                        <h3 className="pro-price"> ₹ {item.price} / {item.unit}</h3>
                        <p className="pro-desc"> <i className="fa-solid fa-map-marker-alt"></i> {user.userData.district}, {user.userData.state}</p>
                        <button onClick={() => { removeItem("queue", item.id, item.imgUrl) }} className="btn remove btn-danger" style={{ fontSize: 12 }}> <i className="fa-solid fa-trash"></i> Remove</button>
                      </div>
                    </div>
                  )
                })
                  : <h6>No Items in Queue</h6>
                }
              </div>
              <br /><br />
              <div>
                <h5>Your rejected products - रद्द किया गया उत्पाद </h5>
                <div className="card-wrap">
                  {
                    sellerProductRejected.length ?
                      sellerProductRejected.map((item, index) => {
                        return (
                          <div className="product">
                            <div className="img-box-pro">
                              <img loading='lazy' src={item.imgUrl} alt="wheat" className="pro-img" />
                              {
                                item.organic === "yes" && <><div className="green-box"></div></>
                              }
                            </div>
                            <div className="pro-detail">
                              <h3 className="pro-name">{item.cropName}</h3>
                              <h3 className="pro-price"> ₹ {item.price} / {item.unit}</h3>
                              <div>
                                <b>Reason:</b>{item.reason === "img" ? "Your img was perfect" : 'You product is over priced'}
                              </div>
                              <p className="pro-desc"> <i className="fa-solid fa-map-marker-alt"></i> {user.userData.district}, {user.userData.state}</p>
                              {/* <button onClick={() => { removeItem("queue", item.id) }} className="btn remove btn-danger">Remove from Queue</button> */}
                            </div>
                          </div>
                        )
                      })
                      :
                      <h6>No Products are rejected</h6>
                  }
                </div>
              </div>
              <br /><br />
              <div className="d-flex add-items flex-wrap justify-content-between">
                <h5>Your products for sell - बिक्री के लिए आपका उत्पाद</h5>
                {
                  isEditing && <EditBackdrop set={setEditingData} value={editingData} />
                }
                <Link to={`/${user.userData.userType}/add-item-to-store`}>
                  <button className="btn-success btn" style={{ margin: "0 !important" }}>
                    <i className="fa-solid fa-plus"></i>
                    Add Item To Sell - नए फसल डालें
                  </button>
                </Link>
              </div>
              {
                sellerProductVerified.length ?
                  <>
                    <span className="text-danger text-center">
                      keep updating product in every three days if not will get deleted.
                      हर तीन दिनों में उत्पाद को अपडेट करते रहें, यदि नहीं तो हटा दिया जाएगा
                    </span>
                    <div className="card-wrap">
                      {
                        sellerProductVerified.map((item, index) => {
                          // eslint-disable-next-line
                          var item = item.item
                          return (
                            <div className="product" key={index}>
                              <div className="img-box-pro">
                                <img loading='lazy' src={item.imgUrl} alt="wheat" className="pro-img" />
                                {
                                  item.organic === "yes" && <><div className="green-box"></div></>
                                }
                              </div>
                              <div className="pro-detail">
                                <h3 className="pro-name">{item.name}</h3>
                                <h3 className="pro-price"> ₹ {item.price} / {item.unit}</h3>
                                <p className="pro-desc"> <i className="fa-solid fa-map-marker-alt"></i> {user.userData.district}, {user.userData.state}</p>
                                <button onClick={() => { removeItem("fromStore", item.id, item.imgUrl) }} className="btn remove btn-danger">Remove</button>
                                <button onClick={() => { editItem(item) }} className="btn remove btn-primary"><i className="fa fa-edit"></i></button>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </>
                  : <div className="no-login-cart">
                    <div className="circle">
                      <i className="fa-solid fa-store"></i>
                    </div>
                    <h3>No items in your store - आपका स्टोर खाली है</h3>
                    <Link to={'/farmer/add-item-to-store'}><button className="btn btn-success">Add items to sell - फसल या उत्पाद डालें</button></Link>
                  </div>
              }

            </div>
            <br />  <br /> <br />

          </>
        )
      }

    }
    else {
      return (
        <InfoPage title="Your store is locked" desc="You have to make your seller profile to unlock the store" href={"/profile"} />
      )
    }
  }
  else {
    return (
      <InfoPage title="You are at the worng page" desc="This store is only for sellers" href={"/home"} />

    )
  }


}
const EditBackdrop = ({ set, value }) => {
  var name, formValue
  const handleChanges = (e) => {
    formValue = e.target.value
    name = e.target.name
    set({ ...value, [name]: formValue })
  }
  const updateData = () => {
    firebase.database().ref("items").child(value.id).set({
      ...value, lastUpdate: Date.now()
    }).then(() => {
      window.location.reload()
    })
  }
  return (<>
    <div className="backdrop">
      <div className="editing-div">
        <form action="" onSubmit={updateData}>
          <div className="form-group">
            <label>
              Quantity
            </label>
            <input required type="text" className='form-control' value={value.quantity} onChange={handleChanges} name='quantity' />
          </div>
          <div className="form-group">
            <label>
              Price
            </label>
            <input required type="text" className='form-control' value={value.price} onChange={handleChanges} name="price" />
          </div>
          <input type="submit" className='btn btn-primary' value="Update" />
        </form>
      </div>
    </div>
  </>)
}
export default SellerStore