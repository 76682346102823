import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../Component/Common/Loading';
import ProductRow from '../../Component/Products/ProductRow';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import '../../style/Cart.css';
import '../../style/products.css';
import slugify from '../../util/common/slugify';

const AllProduct = () => {
    const [{ category, products }] = useDataLayerValue()

    if (products) {
        return (
            <div  >
                <hr />
                {
                    category.map((category, indexs) => {
                        const newData = products.filter(item =>
                            item.category.includes(category.categorieName)
                        )
                        if (newData.length) {
                            return (
                                <div key={indexs}>
                                    <h3 className="text-center cate-name text-capitalize">
                                        <Link to={`/category/${slugify(category.categorieName)}`}>
                                            {category.categorieName}
                                        </Link>

                                    </h3>
                                    <ProductRow category={category.categorieName} newData={newData} />
                                </div>

                            )
                        }

                    })
                }

            </div >
        )
    }
    else {
        return <Loading />
    }

}
export { AllProduct };
