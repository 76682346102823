import React, { useState } from "react";
import Swal from "sweetalert2";
import Footer from "../Component/Footer";
import Header from "../Component/Common/Header/Header"
import firebase from "../firebase";
import "../style/contactus.css";

function ContactPage() {
  const [formData, setFromData] = useState()
  const submitContactForn = (e) => {
    e.preventDefault()
    var jsx = /^[A-Z a-z]+$/;
    if (formData.name.match(jsx)) {
      if (formData.phone && formData.message) {
        firebase.database().ref("contactMail").push({...formData, timeStamp: Date.now()}).then(() => {
          Swal.fire("Form submited successfully", "We will replay you soon", "success")
        })
      }
      else {
        Swal.fire("Invalid input please enter all the required details", "", "error")
      }
    }
    else {
      Swal.fire("Name Should contain only letter no numbers allowed", "", "error")
    }

  }

  let name, value
  const handleFormChanges = (e) => {
    name = e.target.name
    value = e.target.value
    setFromData({
      ...formData,
      [name]: value
    })
  }
  return (
    <>
      <Header />
      <div className="main">
        <form onSubmit={submitContactForn} className="form">
          <h1 className="contactus-heading">Contact Us - संपर्क करे</h1>
          <input
            className="inputs-contact"
            placeholder="Your Name - अपना नाम"
            onChange={handleFormChanges}
            type="text"
            required
            name="name"
            id="name"
          // pattern="/^[A-Za-z]$/"
          // title="Invelid"
          />
          <input
            className="inputs-contact"
            placeholder="Your email ID (optional) - अपना ईमेल"
            onChange={handleFormChanges}
            type="email"
            name="mail"
            id="mail"
          />
          <input
            type="text"
            name="phone"
            pattern="[0-9]+"
            onChange={handleFormChanges}
            maxlength="10"
            minlength="10"
            placeholder="Mobile no. - मोबाइल नंबर"
            className="inputs-contact"
            required />

          <input
            className="inputs-contact"
            placeholder="Landmark /Address (optional) - पता"
            onChange={handleFormChanges}
            type="text"
            name="address"
            id="Address"
          />
          <br />
          <input
            className="inputs-contact"
            placeholder="Subject (optional) - संपर्क का विषय"
            onChange={handleFormChanges}
            type="text"
            name="subject"
            id="subject"
          />
          <textarea
            className="inputs-contact"
            placeholder="Please describe your issue or message here Properly - विषय का विस्तार"
            name="message"
            id="message"
            cols="30"
            rows="6"
            onChange={handleFormChanges}
            required
          ></textarea>
          <input className="inputs-contact"
            type="submit" value="Send" />
        </form>

      </div>

      <Footer />
    </>
  );
}

export default ContactPage;
