import firebase from "../firebase";

const fetchCrops = () => {
    return new Promise(async (resolve) => {
        var cropsRef = firebase.database().ref('crops/').once('value')
        var data = await cropsRef.then((snapshot) => {
            var snapVal = snapshot.val();
            const rawCropsList = [];
            for (let id in snapVal) {
                rawCropsList.push({ id, ...snapVal[id] })
            }
            return rawCropsList
        })
        resolve(data)
    })

}

export default fetchCrops