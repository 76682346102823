// import Col from "react-bootstrap/Col";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
import React from "react"
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import "../style/footer.css";
import { BsTwitter, BsLinkedin, BsInstagram, BsFacebook, BsYoutube } from "react-icons/bs";

import { Link } from "react-router-dom"
export default function Footer() {
  return (
    <MDBFooter style={{ backgroundColor: "#1a1e21", paddingBottom: window.innerWidth < 600 ? 80 : 0 }} bgColor="dark" className="text text-light text-lg-left">
      <MDBContainer className="p-4">
        <MDBRow>
          <MDBCol lg="4" md="12" sm="12" className="mb-4 mb-md-0">
            <h5 className="text-uppercase mb-0">About us</h5>
            <br />
            <p style={{ color: "#fff" }}>
              eKisan Darshan किसानों को कृषि की दुनिया का नेतृत्व करने में सक्षम
              बनाता है। यहां निवेशक, बाजार और उपभोक्ता किसी भी क्षेत्र के किसान
              के साथ संपर्क कर सकते हैं।
            </p>
            <p style={{ color: "#fff" }}>Follow us on</p>
            <a href="https://m.facebook.com/ekisandarshan.in/" target="_blank" className="me-2">
              <BsFacebook />
            </a>
            <a href="https://www.instagram.com/ekisandarshan/" target="_blank" className="m-2">
              <BsInstagram />
            </a>
            <a href="https://twitter.com/ekisandarshan" target="_blank" className="m-2">
              <BsTwitter />
            </a>
            <a href="https://www.linkedin.com/in/ekisan-darshan-411785243" target="_blank" className="m-2">
              <BsLinkedin />
            </a>
            <a href="https://www.youtube.com/channel/UCary0JMBgjVxTpwFpPsLcxg" target="_blank" className="m-2">
              <BsYoutube />
            </a>
          </MDBCol>

          {/* links */}
          <MDBCol lg="4" md="6" sm="12" className="text-lg-center mb-4 mb-md-0">
            <h5 className="text-uppercase mb-0">Quick Links</h5>
            <br />
            <ul className="list-unstyled">
              <li>
                <Link to={"/home"} className="text-light">
                  Home
                </Link>
              </li>
              <li>
                <Link to={"/profile"} className="text-light">
                  Profile
                </Link>
              </li>
              <li>
                <Link to={"/cart"} className="text-light">
                  Cart
                </Link>
              </li>
              <li>
                <Link to={"/contact"} className="text-light">
                  Contact us
                </Link>
              </li>
              <li>
                <Link to={"/about"} className="text-light">
                  About us
                </Link>
              </li>
            </ul>
          </MDBCol>

          <MDBCol lg="4" md="6" sm="12" className="mb-4 mb-md-0">
            <h5 className="text-uppercase mb-0">Contact</h5>
            <br />
            <p style={{ color: "#fff" }}>Address: <a href="https://goo.gl/maps/VnxLxPuLAGacZ6Lo9" >Loknathpur, Dalsinghsarai-848114, Samastipur, Bihar</a></p>
            <p style={{ color: "#fff" }}>
              Phone: <a href="tel:+919131248957"> +91 9131248957</a>
              <br />
              Email:{" "}
              <a href="mailto:info@ekisandarshan.in">
                info@ekisandarshan.in
              </a>
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        &copy; {new Date().getFullYear()} Copyright:{" "}
        <a className="text-light" href="/">
          eKisan Darshan
        </a>
      </div>
    </MDBFooter>
  );
}
