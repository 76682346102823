import React, { useState } from 'react'
import Footer from '../../Component/Footer'
import Header from "../../Component/Common/Header/Header"
import "../../style/TalkToExpert.css"
import firebase from "../../firebase"
import { useDataLayerValue } from '../../DataLayer/DataLayer'
import Swal from 'sweetalert2'
function TalkToExpert() {
    const [{ user }] = useDataLayerValue()
    const [formData, setFromData] = useState()
    const imgExtRemover = () => {
        var fileName = formData.image.name;
        return fileName.split('.').pop()
    }
    const submitContactForn = (e) => {
        e.preventDefault()
        var TIME_STEMP = Date.now()
        firebase.storage().ref("expert_chats/").child(`${TIME_STEMP}.${imgExtRemover()}`).put(formData.image, () => {
        }).then(() => {
            firebase.storage().ref('/expert_chats').child(`${TIME_STEMP}.${imgExtRemover()}`).getDownloadURL().then((event) => {
                delete formData.image
                firebase.database().ref('expert_chats').push({ ...formData, imgUrl: event, sellerUID: user.userAuthData.uid, userName: user.userData.name, userPhone: user.userAuthData.phoneNumber, timeStamp: TIME_STEMP }).then(() => {
                    Swal.fire("Message Sent Successfully!", '', 'success').then(() => {})
                })
            })
        })
    }
    let value, name;
    const handleFormChanges = (e) => {
        name = e.target.name
        value = e.target.value
        setFromData({
            ...formData,
            [name]: value
        })
    }
    return (
        <>
            <Header />
            <div className="container py-5">
                <form onSubmit={submitContactForn}>
                    <h3 className="text-center mt-4">Connecting experts for agricultural support.</h3>
                    <h3 className="text-center mb-4">कृषि संबंधित जानकारी या सलाह के लिए हमारे विशेषज्ञों से संपर्क करें</h3>
                    <textarea
                        type="text"
                        required
                        onChange={handleFormChanges}
                        name="message"
                        id="Subject"
                        rows={10}
                        className="form-control"
                        placeholder="Subject/बिषय - write about the information or help you need/ विस्तार से लिखें जिसके बारे में जानकारी या सलाह चाहिए"
                    />          
                    <br />
                    <label htmlFor="image-input">
                        Images shows the problem / तस्वीरें समस्या दिखाती हैं
                    </label>
                    <br />
                    <input
                        type="file"
                        name="image-input"
                        id="image-input"
                        accept="image/*"
                        className="form-control"
                        required
                        onChange={(e) => { setFromData({ ...formData, image: e.target.files[0] }) }}
                    />
                    <br />
                    <br />
                    <button
                        className="form-control text-light bg-primary"
                    >
                        Send
                    </button>
                    <br />
                    <br />
                </form>
            </div>
            <Footer />
        </>
    );

}
export default TalkToExpert