import firebase from "../../firebase"

const fetchProductData = (id) => {
    return new Promise(async (resolve) => {
        var cropsRef = firebase.database().ref('items/').child(id).once('value')
        var data = await cropsRef.then((snapshot) => {
            var snapVal = snapshot.val();
            return {...snapVal, id: id}
        })
        resolve(data)
    })
}
export default fetchProductData