import React from 'react'
import LoadingGif from "../../assets/images/loaders.gif"
function Loading({ zIndex = 99 }) {
    return (
        <div style={{ zIndex: zIndex }} className="loading-screen">
            <div style={{ height: "100vh", width: "100vw", background: "#fff" }} className="d-flex justify-content-center align-items-center">
                {/* <img src="https://flevix.com/wp-content/uploads/2020/02/Loading-Image-1.gif" alt="" /> */}
                <img style={{ width: "500px" }} src={LoadingGif} alt="" />
            </div>
        </div>
    )
}

export default Loading