import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import firebase from '../../firebase';
import '../../style/login.css';
import React from 'react'
import Logo from "../../assets/images/logo.jpeg";
import Loading from '../../Component/Common/Loading';
import Header from '../../Component/Common/Header/Header';
function LoginPage() {

  const [otpStates, setOtpStates] = useState(false)
  const [otpIsLoading, setOtpIsLoading] = useState(false)

  const [{ user, authInitilize }] = useDataLayerValue()
  const [formData, setFormData] = useState({
    phone: '',
    password: ''
  })

  const OtpMain = (props) => {
    const [EnteredOTP, setEnteredOTP] = useState()
    const [resend, setResend] = useState(false)
    setOtpIsLoading(false)
    useEffect(() => {
      setTimeout(() => {
        setResend(true)
      }, 50000)
    }, [])
    const verifyOtp = (e) => {
      e.preventDefault()
      var confirmationResult = window.confirmationResult
      confirmationResult.confirm(EnteredOTP).then((result) => {
        setOtpStates(false)
        var uid = result.user.uid
        firebase.database().ref('users/').child(uid).on('value', (snapshot) => {
          var user = snapshot.val()
          if (user === null) {
            window.location.replace("/register")
          }
          else {
            window.location.replace("/home")
          }
        })
      }).catch((error) => {
        if (error.code === "auth/invalid-verification-code") {
          Swal.fire("Invalid OTP", "Enter correct OTP and try again", 'error')
        }
      })

    }
    return (
      <>
        <div className="otp-main">
          <div className="card login-box">
            <form onSubmit={verifyOtp}>
              <h3 className="bg text-light rounded">OTP Verification</h3>
              <br />
              <h4 className="text">Enter OTP received on your Phone no. <span className="mob">{props.phoneNo}</span></h4>
              <br />
              <input className="input-group-text" pattern='[0-9]{6}' value={EnteredOTP} onChange={(e) => { setEnteredOTP(e.target.value) }} type="number" />
              <br />
              <h5 style={{ visibility: resend ? "visible" : "hidden" }}>Didn't received OTP? <a onClick={props.signIn} href="#">Resend OTP</a></h5>
              <br />
              <button className="btn-block submit-btn" type="submit">Submit</button>
            </form>
          </div>
        </div>

      </>
    )
  }
  const setUpRecaptch = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        signIn();
      }
    });
  }
  const SendOtpLoding = () => {
    return (
      <div style={{ zIndex: 400, background: "#00000066", width: "100vw", height: "100vh", position: "fixed", transform: "translate(-50%, -50%)", top: "50%", left: "50%" }}>
        <div className="h-100 d-flex justify-content-center align-items-center">
          <img src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif" alt="" />
        </div>
      </div>
    )

  }
  const signIn = (e) => {
    e.preventDefault()
    setOtpIsLoading(true)
    setUpRecaptch()


    const phoneNumber = `+91${formData.phone}`;
    const appVerifier = window.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier).catch((error) => {
      if (error.code === "auth/invalid-phone-number") {
        Swal.fire("Invalid phone number", "Enter correct phone number and try again", 'error').then(() => {
          window.location.reload()
        })
      }
    })
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setOtpStates(true)
      }).catch((error) => {
        console.error(error)
      });
  }
  if (authInitilize) {
    return <Loading />
  }
  else {

    return (

      <>
        {
          otpStates && <OtpMain signIn={signIn} phoneNo={formData.phone} />
        }
        {
          otpIsLoading && <SendOtpLoding />
        }
        <Header />
        <div className="login-page" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

          <div className="container p-lg-5 mt-lg-5 mt-1 p-3" >
            <div className="row bg-success rounded-5 p-lg-4" style={{ borderRadius: 30 }}>
              <div className="col-lg-6 col-md-12 px-lg-5 p-4 d-lg-block d-none">
                <img
                  src={Logo}
                  className="rounded-circle p-lg-5 img-fluid"
                  alt=""
                />
              </div>
              <div className="col-lg-6 col-md-12 p-5">
                <h1 className="text-center text-light mt-lg-5 pt-lg-1">
                  eKisan Darshan
                  <br />
                  Login / Registration
                </h1>
                <p className="text-center text-light my-4">
                  An OTP will br Sent to this Number for Verification
                </p>
                <form onSubmit={signIn}>
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    className="form-control bg-light text-center"
                    onChange={(event) => { setFormData({ ...formData, phone: event.target.value }) }}
                  />
                  <div className="p-2">
                    <input
                      className="btn btn border border-light btn-block w-100 mx-auto mt-1 text-light"
                      type="submit"
                      value="Login"
                    />
                  </div>
                  <div id="recaptcha-container"></div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );

  }

}


export default LoginPage;