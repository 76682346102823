
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Loading from '../../Component/Common/Loading';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import firebase from '../../firebase';
import '../../style/registration.css';
import fetchCrops from '../../util/fetchCrops';
import Header from '../../Component/Common/Header/Header';
function FarmerDataForm() {
    const [{ user, category }] = useDataLayerValue()
    const [crops, setCrops] = useState([])
    const [formData, setFormData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 2000)
    }, [])
    useEffect(() => {
        const loadData = async () => {
            setCrops(await fetchCrops())
        }
        loadData()
    }, [])
    let name, value;
    const userAuthData = user.userAuthData;
    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }
    const createFarmerAccount = (e) => {
        e.preventDefault()
        var uid = userAuthData.uid
        var farmerData = {
            ...formData, crops: [{
                cropName: formData.cropName,
                cropType: formData.cropType,
                dateOfSowing: formData.dateOfSowing,
                fieldArea: formData.fieldArea,
                fieldSizeUnit: formData.fieldSizeUnit
            }]
        }

        delete farmerData.cropName
        delete farmerData.cropType
        delete farmerData.dateOfSowing
        delete farmerData.fieldArea
        delete farmerData.fieldSizeUnit
        firebase.database().ref('users').child(uid).child("farmerData").set({ ...farmerData, lastUpdate: Date.now() }).then(() => {
            Swal.fire("Farmer Profile Created Successfully!", '', 'success').then(() => { window.location.replace('/profile') })
        })
    }


    if (isLoading === true) {
        return <Loading />
    }
    else { }
    if (userAuthData !== null) {
        if (user.userData !== null) {
            if (user.userData.userType === "farmer") {
                if (!user.userData.farmerData) {
                    return (
                        <>
                            <Header />
                            <div className="registration">
                                <form onSubmit={createFarmerAccount}>
                                    <div id="wrapper">
                                        <h4>Create Farmer Profile</h4>
                                        <br />
                                        <div className="crops">
                                            <h5>Farm 1</h5>
                                            <div className="d-flex justify-content-center">
                                                <select required name="cropType" onChange={handleFormChanges} id="inputState">
                                                    <option value="">Crop Category - फसल का प्रकार</option>
                                                    {
                                                        category.map((item, index) => {
                                                            if (
                                                                item.categorieName !== "Fertilizers & Pesticides - उर्वरक & कीटनाशक"
                                                                &&
                                                                item.categorieName !== "Farm Machinery - कृषि मशीनरी"
                                                                &&
                                                                item.categorieName !== "Irrigation Equipment - सिंचाई उपकरण"
                                                            ) {
                                                                return <>
                                                                    <option value={item.categorieName}>{item.categorieName}</option>
                                                                </>
                                                            }
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <select name="cropName"
                                                required
                                                disabled={formData.cropType === undefined || formData.cropType === "" ? true : false}
                                                onChange={handleFormChanges} id="inputState">
                                                <option value="">Which Crop you grow - फ़सल का नाम</option>
                                                {
                                                    crops.map((item, index) => {
                                                        if (item.category === formData.cropType) {
                                                            return <>
                                                                <option value={item.cropName}>{item.cropName}</option>
                                                            </>
                                                        }
                                                        return null
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <h5>
                                            Date of Sowing - फसल लगाने की तिथि
                                        </h5>
                                        <div className="d-flex justify-content-center">
                                            <input required onChange={handleFormChanges} type="date" name="dateOfSowing" placeholder="Date of sowing" />
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-9">
                                                <div className="d-flex justify-content-center">
                                                    <input
                                                        required
                                                        onChange={handleFormChanges}
                                                        type="number"
                                                        name="fieldArea"
                                                        placeholder="Area of your farm - कितने जमीन पर लगाए हैं?"
                                                        step="any"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="d-flex justify-content-center">
                                                    <select name="fieldSizeUnit"
                                                        disabled={formData.cropType === undefined || formData.cropType === "" ? true : false}
                                                        onChange={handleFormChanges} id="inputState"
                                                        required
                                                    >
                                                        <option value="">Unit of Messurement line acre, hactor - खेत मापने का नाम ?</option>
                                                        Hectare/acre//Katta
                                                        <option value="hectare">Hectare - हैक्टर</option>
                                                        <option value="acre">Acre - एकड़</option>
                                                        <option value="bigha">Bigha - बीघा</option>
                                                        <option value="katta">Katta - कट्टा</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>




                                        <div className='selection-wrap'>
                                            <div className='selection-title'>
                                                <span className="en">Irrigation Facility? -</span> सिंचाई सुविधा?
                                            </div>
                                            <div className='radio-wrap'>
                                                <input onChange={handleFormChanges} type="radio" id="irrigation_yes" name="irrigation" value={"yes"} /><label htmlFor="irrigation_yes">हाँ है</label>
                                                <input onChange={handleFormChanges} type="radio" id="irrigation_no" name="irrigation" value={"no"} /><label htmlFor="irrigation_no">नहीं है</label>
                                            </div>
                                        </div>

                                        <div className='selection-wrap'>
                                            <div className='selection-title'>
                                                <span className="en">Storage Facility? -</span> भंडारण सुविधा ?
                                            </div>
                                            <div className='radio-wrap'>
                                                <input onChange={handleFormChanges} type="radio" id="storage_yes" name="storage" value={"yes"} /><label htmlFor="storage_yes">हाँ है</label>
                                                <input onChange={handleFormChanges} type="radio" id="storage_no" name="storage" value={"no"} /><label htmlFor="storage_no">नहीं है</label>

                                            </div>
                                        </div>

                                        <div className='selection-wrap'>
                                            <div className='selection-title'>
                                                <span className="en">Chemical fertilizers? -</span> रासायनिक खाद ?
                                            </div>
                                            <div className='radio-wrap'>
                                                <input onChange={handleFormChanges} type="radio" id="chemical_fertilizer_yes" name="chemicalFertilizers" value={"yes"} /><label htmlFor="chemical_fertilizer_yes">हाँ है</label>
                                                <input onChange={handleFormChanges} type="radio" id="chemical_fertilizer_no" name="chemicalFertilizers" value={"no"} /><label htmlFor="chemical_fertilizer_no">नहीं है</label>
                                            </div>
                                        </div>

                                        <div className='selection-wrap'>
                                            <div className='selection-title'>
                                                <span className="en">Soil tested? -</span> मिट्टी जांच ?
                                            </div>
                                            <div className='radio-wrap'>
                                                <input onChange={handleFormChanges} type="radio" id="soil_tested_yes" name="soilTested" value={"yes"} /><label htmlFor="soil_tested_yes">हाँ है</label>
                                                <input onChange={handleFormChanges} type="radio" id="soil_tested_no" name="soilTested" value={"no"} /><label htmlFor="soil_tested_no">नहीं है</label>
                                            </div>
                                        </div>
                                        {/* <StateDisctrict formData={formData} handleFormChanges={handleFormChanges} /> */}
                                        <br />
                                        <input className="theme-btn" value="Submit" id="submit" type="submit" />
                                        <br /><br />
                                        <div id="recaptcha-container"></div>

                                    </div>
                                </form >
                            </div >
                        </>
                    )
                }
                else {
                    window.location.replace("/profile")
                }
            }
            else {
                window.location.replace("/")
            }
        }
        else {
            window.location.replace("/home")
        }
    }
    else {
        window.location.replace("/login")
    }
}

export default FarmerDataForm
