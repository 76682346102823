import React from 'react'
import InfoMessage from '../Common/UI Components/InfoMessage'
import { useDataLayerValue } from '../../DataLayer/DataLayer'

export const Orders = () => {
    const [{ user }] = useDataLayerValue()
    const [userOrders, setUserOrder] = React.useState([])

    React.useEffect(() => {
        var tempArr = []
        for (let id in user.userData.orders) {
            tempArr.push({ id: id, ...user.userData.orders[id] })
        }
        setUserOrder(tempArr)
    }, [])
    return (
        <div>
            {
                userOrders.length ? userOrders.map((item, index) => {
                    return (
                        <OrdersCard item={item} index={index} />
                    )
                })
                    :
                    <InfoMessage
                        icon="fa-shopping-bag"
                        title="You haven't order any thing"
                        href="/home"
                        btnTxt="Continue Shopping"
                    />
            }
        </div>
    )
}
const OrdersCard = (props) => {
    var item = props.item
    // item = products.filter(item => item.id === props.item.itemId)
    // item = item[0]
    var timeStamp = props.item.timeStamp
    return (
        <div className="product">
            <div className="img-box-pro">
                <img src={item.imgUrl} alt="wheat" className="pro-img" />
                {
                    item.organic === "yes" && <><div className="green-box"></div></>
                }
            </div>
            <div className="pro-detail">
                <h3 className="pro-name">{item.name}</h3>
                <h3 className="pro-price"> ₹ {item.price}</h3>
                <b>Status:</b> {
                    item.status ? item.status : <>
                        Order Place <br />
                    </>
                }
                {
                    item.status === "accepted" && <>
                        <b> Estimated Days to ready the order:</b> {item.estimatedDays}
                    </>
                }
                {/* <h3 className='pro-price'>Estimated Delivery Date <b>{`${deleveryDate + 3}/${deleveryMonth + 1}/${deleveryYear}`}</b></h3> */}
            </div>
        </div>
    )
}
