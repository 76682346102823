import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from "../../Component/Common/Header/Header"
import Loading from '../../Component/Common/Loading';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import firebase from "../../firebase";
import "../../style/SellerProfile.css";
import ProductCard from '../../Component/Products/ProductCard';
function SellerProfile() {
    const sellerId = useLocation().pathname.split("/")[3]
    const [sellerData, setSellerData] = useState([])
    useEffect(() => {
        var state = ""
        var district = ""
        var sellerName = ""
        var block = ""
        var userType = ""
        firebase.database().ref('users/').child(sellerId).child("state").once('value').then((snapshot) => {
            state = snapshot.val()

        }).then(() => {
            firebase.database().ref('users/').child(sellerId).child("district").once('value').then((snapshot) => {
                district = snapshot.val()
            }).then(() => {
                firebase.database().ref('users/').child(sellerId).child("block").once('value').then((snapshot) => {
                    block = snapshot.val()
                }).then(() => {
                    firebase.database().ref('users/').child(sellerId).child("name").once('value').then((snapshot) => {
                        sellerName = snapshot.val()
                    })
                        .then(() => {
                            firebase.database().ref('users/').child(sellerId).child("userType").once('value').then((snapshot) => {
                                userType = snapshot.val()
                                setSellerData({
                                    state,
                                    district,
                                    sellerName,
                                    userType,
                                    block
                                })
                            })
                        })
                })
            })
        })



    }, [sellerId])
    if (sellerData.userType === "farmer" || sellerData.userType === "corporate") {
        if (sellerData === null) {
            return <h1>No User Found</h1>
        }
        else if (sellerData) {
            return <MainProfile sellerData={sellerData} />
        }
        else {
            return <h1>No User Found</h1>
        }
    }
    else if (!sellerData) {
        return <Loading />
    }
}

const MainProfile = (props) => {
    const sellerId = useLocation().pathname.split("/")[3]
    const [{ products }] = useDataLayerValue()
    const sellerData = props.sellerData
    const [sellerListedProducts, setSellerListedProducts] = useState([])
    useEffect(() => {
        var tempList = []
        // eslint-disable-next-line
        products.map((item) => {
            if (item.sellerUID === sellerId) {
                tempList.push({ ...sellerListedProducts, ...item })
            }
            setSellerListedProducts(tempList)
        })
    })
    return (
        <>
            <Header />
            <div className="container seller-profile rounded bg-white mt-5">
                <div className="row p2">
                    <div className="col p2">
                        <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                            <img
                                loading='lazy'
                                className="mb-3 rounded-circle"
                                width="150px"
                                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                                alt="profile"
                            />
                            <span className="font-weight-bold">{sellerData.sellerName}</span>
                            <span className="text-black-50">
                                {sellerData.block ? sellerData.block : sellerData.district}, {sellerData.state}
                            </span>
                            <span className="text-black-50">
                                {sellerData.userType === "farmer" ? "Farmer" : sellerData.userType === "corporate" && "Corporate"}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="p-3 py-5">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="text">My Products</h4>
                            </div>
                            <div className="card-wrap">
                                {
                                    sellerListedProducts.map((item, index) => {
                                        return (
                                            <ProductCard data={item} />
                                        )
                                    }
                                    )

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default SellerProfile