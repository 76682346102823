import { Link } from "react-router-dom"
import React, { useEffect, useState } from 'react'
import ProductCard from "./ProductCard"
import { useDataLayerValue } from "../../DataLayer/DataLayer"
import slugify from "../../util/common/slugify"

const ProductRow = ({ newData, category }) => {
    const [{ user }] = useDataLayerValue()
    const [list, setList] = useState([])
    useEffect(() => {
        if (user.userData) {
            const rowlist = []
            newData.map((item) => {
                if (item.block === user.userData.block) {
                    rowlist.push(item)
                }
            })
            newData.map((item) => {
                if (item.district === user.userData.district) {
                    rowlist.push(item)
                }
            })
            newData.map((item) => {
                if (item.state === user.userData.state) {
                    rowlist.push(item)
                }
            })
            newData.map((item) => {
                rowlist.push(item)
            })
            var filterList = rowlist.filter((item, index) => rowlist.indexOf(item) === index);

            setList(filterList)
        }
        else {
            setList(newData.reverse())
        }
    }, [])
    return (
        <>
            <div className="card-wrap">
                {
                    list.slice(0, 8).map((item, index) => {
                        return (

                            <ProductCard
                                index={index}
                                data={item}
                                key={index}
                            />

                        )
                    })
                }
                {
                    newData.length > 12 &&
                    <div className="d-flex aligin-items-center align-items-center">
                        <Link to={`/category/${slugify(category)}`}>
                            <button className="loadMore">Load More</button>
                        </Link>
                    </div>
                }
            </div>
        </>
    )
}
export default ProductRow