import React from 'react'
import { Link } from 'react-router-dom'
import { useDataLayerValue } from '../../DataLayer/DataLayer'
import slugify from '../../util/common/slugify'
import ProductRow from '../Products/ProductRow'

function Farmer() {
  const [{ products }] = useDataLayerValue()
  return (
    <>
      {
        // eslint-disable-next-line
        ["Fertilizers & Pesticides - उर्वरक & कीटनाशक", "Farm Machinery - कृषि मशीनरी", "Irrigation Equipment - सिंचाई उपकरण"].map((category, indexs) => {
          const newData = products.filter(item =>
            item.category.includes(category)
          )
          if (newData.length) {
            return (
              <div key={indexs}>
                <h3 className="text-center cate-name text-capitalize">
                  <Link to={`/category/${slugify(category)}`}>
                    {category}
                  </Link>

                </h3>
                <ProductRow category={category} newData={newData.reverse()} />
              </div>

            )
          }

        })
      }
    </>
  )
}

export default Farmer