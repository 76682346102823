import React from "react"
import { Link } from "react-router-dom";
import Footer from "../../Component/Footer";
import Header from "../../Component/Common/Header/Header"
import Loading from "../../Component/Common/Loading";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
const SellerNav = () => {
    const [{ user, authInitilize }] = useDataLayerValue()
    const userData = user.userData

    if (authInitilize || userData === undefined) {
        return <Loading />
    }
    else {
        if (userData !== null) {
            return (
                <>
                    <Header />
                    <div className="container px-lg-5 py-5" style={{ marginTop: 80 }}>
                        <div className="row px-lg-5">
                            <div className="col-12 col-lg-4 p-2 btn">
                                <Link style={{ textDecoration: "none" }} to={`/profile`}>
                                    <div className="seller-nav-btn h-10 bg-primary rounded py-2 px-4">
                                        <div className="img-fluid">

                                        </div>
                                        <h2 style={{ fontSize: 22 }} className="py-2 text-light">My Profile - मेरा प्रोफ़ाइल</h2>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-lg-4 p-2 btn">
                                <Link style={{ textDecoration: "none" }} to={`/my-store/placed-orders`}>
                                    <div className="seller-nav-btn h-10 bg-danger  rounded py-2 px-4">
                                        <div className="img-fluid">

                                        </div>
                                        <h2 style={{ fontSize: 22 }} className="py-2 text-light">My Orders- मेरा आर्डर</h2>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-lg-4 p-2 btn">
                                <Link style={{ textDecoration: "none" }} to={`/my-store/shop`}>
                                    <div className="seller-nav-btn h-10 bg-info rounded py-2 px-4">
                                        <div className="img-fluid">
                                        </div>
                                        <h2 style={{ fontSize: 22 }} className="py-2 text-light">My Store- माई स्टोर</h2>
                                    </div>
                                </Link>
                            </div>
                            {
                                user.userData.userType === "farmer" &&
                                <>
                                    <div className="col-12 col-lg-4 p-2 btn">
                                        <Link style={{ textDecoration: "none" }} to={`/talk-to-expert`}>
                                            <div className="seller-nav-btn h-10 bg-success rounded py-2 px-4">
                                                <div className="img-fluid">
                                                </div>
                                                <h2 style={{ fontSize: 22 }} className="py-2 text-light">Talk to Expert - कृषि सलाह</h2>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-12 col-lg-4 p-2 btn">
                                        <Link style={{ textDecoration: "none" }} to={`/notifications`}>
                                            <div className="seller-nav-btn h-10 bg-secondary rounded py-2 px-4">
                                                <div className="img-fluid">
                                                </div>
                                                <h2 style={{ fontSize: 22 }} className="py-2 text-light">Notifications</h2>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <Footer />
                </>
            );
        }
        else {
            return <Loading />
        }
    }

};

export default SellerNav;
