import React, { useEffect, useState } from 'react'
import Footer from '../../Component/Footer'
import Header from "../../Component/Common/Header/Header"
import Loading from '../../Component/Common/Loading'
import { useDataLayerValue } from '../../DataLayer/DataLayer'
import "../../style/TalkToExpert.css"
import InfoMessage from '../../Component/Common/UI Components/InfoMessage'
function Notifications() {
    const [{ user, authInitilize }] = useDataLayerValue()



    if (authInitilize || user.userData === undefined) {
        return <Loading />
    }
    else {
        if (user.userAuthData === null) {
            window.location.replace('/login')
        }
        if (user.userData === null) {
            window.location.replace('/register')
        }
        return (
            <NotificationsComponent />
        );
    }
}
function NotificationsComponent() {
    const [{ user }] = useDataLayerValue()
    const [listData, setlistData] = useState([])

    useEffect(() => {
        var notifications = user.userData.notifications
        var tempArr = []
        for (let id in notifications) {
            tempArr.push({ ...notifications[id], id })
        }
        setlistData(tempArr)
    })
    return (
        <>
            <Header />
            <div className="container notifications">

                {
                    listData.length ?
                        <div>
                            <h5 style={{ marginLeft: 20, textAlign: "center" }}>Your Notifications</h5>
                            {
                                listData.map((item, index) => {
                                    return (
                                        <div className="notify-item">
                                            <div className="left">
                                                <h6 className="title">
                                                    Reply from expert for {item.replyedTo?.slice(0, 20)} ....
                                                </h6>
                                                <p className="detail">
                                                    {item.reply}
                                                </p>
                                            </div>
                                            <div className="right">
                                                {/* <img src="/img" alt="" /> */}
                                                <i className="fa fa-commenting"></i>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        :
                        <InfoMessage
                            icon="fa-comment"
                            title="You didn't got any notifications"
                            href="/"
                            btnTxt="Continue Shopping"
                        />
                }
            </div>
            <Footer />
        </>
    );

}
export default Notifications