import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDataLayerValue } from '../../../DataLayer/DataLayer';
import firebase from '../../../firebase';

const CorporateProfileForm = () => {
    const [{ user }] = useDataLayerValue()

    const userData = user.userData;
    const [formData, setFormData] = useState({
        storage: user.userData.corporateData ? user.userData.corporateData.storage : "no"
    })
    let name, value;

    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }
    const handleCheckbox = (event) => {
        name = event.target.name;
        value = event.target.value;
        if (formData[name] === "yes") {
            setFormData({ ...formData, [name]: "no" });
        }
        else {
            setFormData({ ...formData, [name]: "yes" });
        }
    }
    const upDataProfile = (e) => {
        const newFormData = { ...userData.corporateData, ...formData }
        e.preventDefault()
        Object.keys(newFormData).map((col) => {
            if (newFormData[col] === "") {
                delete newFormData[col]
            }
        })
        firebase.database().ref('users').child(user.userAuthData.uid).child("corporateData").set({ ...newFormData, lastUpdate: Date.now() }).then(() => {
            Swal.fire("Profile Updated Successfully!", '', 'success').then(() => { window.location.replace('/profile') })
        })
    }
    const type = [{
        value: "Manufacturer",
        text: "Manufacturer"
    },
    {
        value: "Seller-Vendors-Distributor",
        text: "Seller/Vendors/Distributor"
    },
    {
        value: "FPO-SHG",
        text: "FPO (Farmer Producer Orgnisation)/SHG (Self Help Group)"
    }]
    if (user.userData.corporateData === undefined) {
        return (
            <>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                    <h4>Create your Seller Profile to unlock your store</h4>
                    <Link to="/corporate/create-profile"><button className="btn btn-width btn-success">Create Your Seller Profile</button></Link>
                </div>
            </>
        )
    }

    else {
        return (
            <>
                <div className="profile registration">
                    <form onSubmit={upDataProfile}>
                        <div id="wrapper">
                            <div className="form-group">
                                <input onChange={handleFormChanges} defaultValue={userData.corporateData.name} required className="form-control" type="text" name="name" id="name" placeholder="Company Name" />
                            </div>
                            <div className="form-group">
                                <select disabled onChange={handleFormChanges} defaultValue={userData.corporateData.company_type} required className="form-control" name="company_type" id="company-type">
                                    {
                                        type.map((item, index) => {
                                            return <option key={index} defaultValue={item.value === userData.corporateData.company_type} value={item.value}>{item.text}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <div className="form-group">
                                <input onChange={handleFormChanges} defaultValue={userData.corporateData.contact_person_name} required className="form-control" type="text" name="contact_person_name" id="contact-person-name"
                                    placeholder="Contact Person Name" />
                            </div>
                            <div className="form-group">
                                <input onChange={handleFormChanges} defaultValue={userData.corporateData.contact_person_email} required className="form-control" type="text" name="contact_person_email" id="contact-person-email"
                                    placeholder="Contact Person Email" />
                            </div>
                            <div className="form-group">
                                <input onChange={handleFormChanges} defaultValue={userData.corporateData.contact_person_phone} required className="form-control" type="phone " max="9999999999" min="1000000000" maxLength="10" minLength="10" name="contact_person_phone"
                                    id="contact-person-phone" placeholder="Contact Person Phone" />
                            </div>
                            <div className="form-group">
                                <input onChange={handleFormChanges} defaultValue={userData.corporateData.address} required className="form-control" type="text" name="address" id="address"
                                    placeholder="Company Address (Please provide full address)" />
                                <br />
                                <div className="form-group">
                                    <input onChange={handleFormChanges} className="form-control" type="number" name="gst" id="gst" placeholder="GST IN (optional)" />
                                </div>
                                <div className="form-group">
                                    <input onChange={handleFormChanges} className="form-control" type="number" name="iso" id="iso"
                                        placeholder="ISO Certification Number (optional)" />
                                </div>
                                <div className="form-group">
                                    <input onChange={handleFormChanges} className="form-control" type="text" name="website" id="website"
                                        placeholder="Company Website (optional)" />
                                </div>
                                <div>
                                    <input onChange={handleCheckbox} type="checkbox" defaultChecked={formData.storage === "yes"} id="storage_facility" name="storage" value={"yes"} />
                                    <label className="mx-2" htmlFor="storage_facility">
                                        <span >Storage Facility?</span>
                                    </label>
                                </div>
                            </div>



                            {/* <StateDisctrict formData={formData} handleFormChanges={handleFormChanges} /> */}
                            <br />
                            <input className="form-control" value="Submit" id="submit" type="submit" />
                            <br /><br />
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

export default CorporateProfileForm