import Footer from "../Component/Footer";
import Header from "../Component/Common/Header/Header"
import { useDataLayerValue } from "../DataLayer/DataLayer";
import React from "react"
import { Link } from "react-router-dom";
import slugify from "../util/common/slugify";

const Categories = () => {
    return (
        <>
            <Header />
            <section className="px-lg-5" style={{ marginTop: 60, background: "#f0f0f0" }}>
                <div className="container px-lg-5 py-2">
                    <h1 className="text-success text-center">
                        <span className="border-bottom border-success border-3 px-4 py-2">
                            Categories
                        </span>
                    </h1>
                    <CategoryGrid />
                </div>
            </section>
            <br />
            <Footer />
        </>
    );
};
const CategoryGrid = () => {
    const [{ category }] = useDataLayerValue()

    return (

        <div className="row">
            {
                category.map((item, index) => {
                    return (
                        <Link style={{ textDecoration: "none" }} className="col-4 col-lg-2 text-center" to={`/category/${slugify(item.categorieName)}`} >
                            <div style={{ height: "100%" }}>
                                <div className="my-1 mx-0 border rounded-2 hover-shadow pb-1">
                                    <img loading='lazy' src={item.coverUrl} className="img-fluid rounded-circle p-lg-4 p-2" alt="" />{" "}
                                    <p>{item.categorieName}</p>
                                </div>
                            </div>
                        </Link>
                    )
                })
            }
        </div>

    )
}
export default Categories;
export { CategoryGrid }