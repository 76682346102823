import React from 'react'
import { Carousel } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.css";


function MyCarousel() {
    return (
        <div style={{ marginTop: 60 }}>
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block slide-img"
                        src={"/images/1.webp"}
                        alt="Second slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block slide-img"
                        src={"/images/2.webp"}
                        alt="Second slide"
                    />
                   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block slide-img"
                        src={"/images/3.webp"}
                        alt="Second slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block slide-img"
                        src={"/images/4.webp"}
                        alt="Second slide"
                    />
                   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block slide-img"
                        src={"/images/5.webp"}
                        alt="Second slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block slide-img"
                        src={"/images/6.webp"}
                        alt="Second slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block slide-img"
                        src={"/images/7.jpg"}
                        alt="Second slide"
                    />
             
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block slide-img"
                        src={"/images/8.jpg"}
                        alt="Second slide"
                    />
             
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block slide-img"
                        src={"/images/9.jpg"}
                        alt="Second slide"
                    />
             
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default MyCarousel
