

import emptyCart from '../../assets/images/emptyCart.svg';
import React, { useState } from "react";
import {
  BsDashCircle,
  BsPencilSquare,
  BsPlusLg,
  BsDashLg
} from "react-icons/bs";
import Swal from "sweetalert2"
import firebase from "../../firebase"
import Footer from "../../Component/Footer"
import Header from "../../Component/Common/Header/Header"
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Loading from "../../Component/Common/Loading"
import { Link, useNavigate } from "react-router-dom";
const Cart = () => {
  const [{ authInitilize, user }] = useDataLayerValue()
  if (authInitilize || user.userData === undefined) {
    return <Loading />
  }
  else if (user.userAuthData === null) {
    return (<>
      <Header />
      <div className="no-login-cart">
        <div className="circle">
          <i className="fa fa-arrow-right-to-bracket"></i>
        </div>
        <h3>Please Login to see cart</h3>
        <Link to={'/login'}><button className="btn btn-width   btn-success">Login <i className="fa fa-arrow-right-to-bracket"></i></button></Link>
      </div>
    </>)
  }
  else if (user.userData === null) {
    return (
      <>
        <Header />
        <div className="no-login-cart">
          <div className="circle">
            <i className="fa fa-user"></i>
          </div>
          <h3>Please Register to see cart</h3>
          <Link to={'/register'}><button className="btn btn-width  btn-success">Register</button></Link>
        </div>
      </>
    )
  }
  else {

    return (<CartComponent />)

  }
};
const CartComponent = () => {
  const [{ user, products }] = useDataLayerValue()
  const navigate = useNavigate()
  var userCartData = user.userData.cart
  var rawCartData = [];
  for (let id in userCartData) {
    rawCartData.push({ id, ...userCartData[id] })
  }
  var TEMP_TOTAL_ARR = []
  rawCartData.map((item) => {
    products.map((product, index) => {
      if (item.itemId === product.id) {
        TEMP_TOTAL_ARR.push({ ...product, price: parseFloat(product.price), itemQuantity: parseInt(item.quantity), itemId: item.id })
      }
      return null
    })
    return null
  })
  var cartTotalPrice = 0;
  for (let i in TEMP_TOTAL_ARR) {
    if (TEMP_TOTAL_ARR[i].category === "Farm Machinery - कृषि मशीनरी" || TEMP_TOTAL_ARR[i].category === "Fertilizers & Pesticides - उर्वरक & कीटनाशक" || TEMP_TOTAL_ARR[i].category === "Irrigation Equipment - सिंचाई उपकरण") {
      cartTotalPrice = cartTotalPrice + (parseInt(TEMP_TOTAL_ARR[i].price) * TEMP_TOTAL_ARR[i].itemQuantity)
    }
    else {
      cartTotalPrice = Math.fround(cartTotalPrice + ((TEMP_TOTAL_ARR[i].price + TEMP_TOTAL_ARR[i].price * (5 / 100)) * TEMP_TOTAL_ARR[i].itemQuantity))

    }
  }
  const NavigateToPlaceOrders = () => {
    navigate('/place-order', { state: { cartData: TEMP_TOTAL_ARR, cartTotalPrice: cartTotalPrice, cartId: rawCartData } })
  }
  if (rawCartData.length) {
    return (
      <>
        <Header />
        <div className="cart-container container py-4" style={{ margin: "100px auto" }}>
          <div className="row">
            <h1 className="text-success text-center m-2 mb-3">
              <span className="px-5 rounded py-1 border-bottom  border-3 border-success">
                Cart
              </span>
            </h1>

            <div className="col-lg-7 col-md-12">
              {
                rawCartData.map((item, index1) => {
                  return (
                    <>
                      {
                        products.map((product, index) => {
                          if (item.itemId === product.id) {
                            return (
                              <CartItemCard index={index} item={item} product={product} />
                            )
                          }
                        })
                      }
                    </>
                  )
                })
              }
            </div>
            <div className="col-lg-5 col-md-12 mt-2 ">
              <div className="card p-2">
                <h6>
                  <div className="row">
                    <div className="col-6">
                      <h4 className="text-success">Net Total</h4>
                    </div>
                    <div className="col-6 text-end">
                      <h4 className="text-success">&#8377; {cartTotalPrice}</h4>
                    </div>
                  </div>
                </h6>
                <button onClick={NavigateToPlaceOrders} className="btn btn-width   btn-success btn-block">Next Step</button>
                <h6 className="text-danger">
                  *On selected products minimum order quantity is 100
                </h6>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
        <Footer />
      </>
    )
  }
  else {
    return (
      <>
        <Header />
        <div style={{ height: "100vh" }} className="d-flex justify-content-center w-100 flex-column align-items-center">
          <img loading='lazy' style={{ width: 400, height: 'auto' }} src={emptyCart} alt="empty cart" />
          <h3>No items in cart</h3>
          <Link to="/products">
            <button className="btn-dark btn">Continue Shoping <i className="fa fa-shopping-bag"></i></button>
          </Link>
        </div>
      </>
    )
  }
}
const QualityControl = (props) => {
  const [{ user }] = useDataLayerValue()
  const product = props.product
  const [currentQuantity, setCurrentQuantity] = useState(props.cartId.quantity)
  const increase = () => {
    setCurrentQuantity(currentQuantity + 1)
    firebase.database().ref('users/').child(user.userAuthData.uid).child('cart').child(props.cartId.id).set({ ...props.cartId, quantity: currentQuantity + 1 })
  }
  const decrease = () => {
    if (product.category === "Farm Machinery - कृषि मशीनरी" || product.category === "Fertilizers & Pesticides - उर्वरक & कीटनाशक") {
      if (currentQuantity > 1) {
        setCurrentQuantity(currentQuantity - 1)
        firebase.database().ref('users/').child(user.userAuthData.uid).child('cart').child(props.cartId.id).set({ ...props.cartId, quantity: currentQuantity - 1 })
      }
    }
    else {
      if (currentQuantity > 100) {
        setCurrentQuantity(currentQuantity - 1)
        firebase.database().ref('users/').child(user.userAuthData.uid).child('cart').child(props.cartId.id).set({ ...props.cartId, quantity: currentQuantity - 1 })
      }
      else {
        Swal.fire("Minimum order quantity is 100")
      }
    }
  }
  return (
    <>
      <button onClick={increase} className="btn btn-width   border mx-1">
        <BsPlusLg className="mb-1" />
      </button>
      <button className="btn border p-0 mx-1">
        <h4 className="mt-1 mx-2">{props.cartId.quantity}</h4>
      </button>
      <button onClick={decrease} className="btn btn-width   border m-1">
        <BsDashLg className="mb-1" />
      </button>
    </>
  )
}
const CartItemCard = ({ product, index, item }) => {
  const [{ user }] = useDataLayerValue()
  const deleteItem = () => {
    const ItemRef = firebase.database().ref('users/').child(user.userAuthData.uid).child('cart').child(item.id);
    ItemRef.remove().then(() => {
      Swal.fire(
        'Deleted Successfull',
        "",
        'success'
      )

    }).catch((error) => { console.error(error) })
  }
  return (
    <div className="card my-2">
      <div className="row">
        <div className="col-5">
          <img
            className="p-2"
            loading='lazy'
            src={product.imgUrl}
            style={{
              height: "200px",
              width: "100%",
              objectFit: "contain",
              borderRadius: "12px"
            }}
            alt=""
          />
        </div>
        <div className="col-7 p-2">
          <h5 className="py-2 text-success">{product.name}</h5>
          <h6 className="pro-price"> ₹ {product.category === "Farm Machinery - कृषि मशीनरी" || product.category === "Fertilizers & Pesticides - उर्वरक & कीटनाशक" || product.category==="Irrigation Equipment - सिंचाई उपकरण" ? product.price : (((parseInt(product.price)) + (parseInt(product.price) * (5 / 100)))).toFixed(0)} / {product.unit}</h6>
          <div className="py-2">
            <QualityControl product={product} cartId={item} />
            <br />
            <button onClick={deleteItem} className="btn btn-width    btn-danger btn-sm m-1">
              Remove
              <BsDashCircle className="mx-1 mb-1" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Cart; 
