class User {
    constructor(user) {
        this.user = user
        this.userData = user.userData
        this.authData = user.userAuthData
        this.setUserStatus()
    }
    setUserStatus() {
        const userFirebaseAuth = this.authData
        const userData = this.userData
        if(!this.user){
            this.isRegisted = false
            this.isLogedIn = false
        }
        if (userFirebaseAuth === null) {
            this.isLogedIn = false
        }
        else if (userData === null) {
            this.isRegisted = false
        }
        else {
            this.isRegisted = true
            this.isLogedIn = true
        }
    }
}


export default User