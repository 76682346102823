import React from 'react';
import { useParams } from 'react-router-dom';
import Header from "../../Component/Common/Header/Header";
import Loading from '../../Component/Common/Loading';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import '../../style/Cart.css';
import '../../style/products.css';
import ProductCard from '../../Component/Products/ProductCard';
import InfoMessage from '../../Component/Common/UI Components/InfoMessage';
function FilteredByBlock() {
    const [{ products }] = useDataLayerValue()
    const Params = useParams()

    if (products.length) {
        return (
            <>
                <Header />
                {
                    Params.block !== undefined ? <><br /><br /> <FilteredProducts itemsData={products} /></> : <Loading />
                }
            </>
        )
    }
    else {
        return <Loading />
    }
}

const FilteredProducts = (props) => {
    const [{ products }] = useDataLayerValue()
    const Params = useParams()
    const itemsData = props.itemsData
    var filteredData = []

    if (products.length) {
        // eslint-disable-next-line
        products.map((item, index) => {
            if (item.block === Params.block) {
                filteredData.push(item)
            }
        })
    }

    if (itemsData) {
        if (filteredData.length) {
            return (
                <>
                    <div className="py-5 " >
                        <hr />
                        <div>
                            <h3 className="text-center text-capitilize">
                                Products available in {Params.state}
                            </h3>

                            <div className="card-wrap" style={{ flexWrap: "wrap", justifyContent: "center" }}>
                                {

                                    filteredData.map((item, index) => {

                                        return (
                                            <>
                                                <ProductCard index={index} data={item} />
                                            </>
                                        )

                                    })



                                }
                            </div>

                        </div>
                    </div>
                </>
            )
        }
        else {
            return (
                <InfoMessage
                    title="Products of this category coming soon to your area."
                    icon="fa-info-circle"
                    btnTxt="Continue Shopping"
                    href={"/"}
                />
            )
        }
    }
    else {
        return <Loading />
    }

}


export default FilteredByBlock;
