import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDataLayerValue } from '../../DataLayer/DataLayer'
import User from '../../DataLayer/User'
import addToCart from '../../util/products/addToCart'
import slugify from '../../util/common/slugify'
const ProductCard = (props) => {
    const navigate = useNavigate()
    const [{ user }] = useDataLayerValue()
    var itemData = props.data
    const userObj = new User(user)
    const cartProps = {
        user: userObj,
        curruntUser: user,
        item: itemData, navigate
    }
    const price = () => {
        if (itemData.unit === "piece") {
          return itemData.price
        }
        else {
          return `${itemData.price} / ${itemData.unit}`
        }
      }
    // itemData.slug = `/category/${slugify(itemData.category)}/${slugify(itemData.name)}/${itemData.id}`


    return (
        <>
            <div key={props.index} className={`product product-card ${props.horizontal && "horizontal"}`}>
                <div className="img-box-pro">
                    <a href={ `/category/${slugify(itemData.category)}/${slugify(itemData.name)}/${itemData.id}` || ""}>
                        <img style={{ cursor: "pointer" }} src={itemData.imgUrl} alt="wheat" loading='lazy' className="pro-img" />
                    </a>
                    {
                        itemData.organic === "yes" && <><div className="green-box"></div></>
                    }


                </div>
                <div className="pro-detail">
                    <h3 className="pro-name">{itemData.name}</h3>
                    <h3 className="pro-price">
                        ₹ {price()}
                    </h3>
                    <p className="pro-desc"> <i className="fa-solid fa-map-marker-alt"></i> {itemData.block ? itemData.block : itemData.district}, {itemData.state}</p>
                    <button onClick={addToCart(cartProps)} className="btn remove btn-success">
                        <i className="fa-solid fa-cart-shopping"></i> Add To Cart
                    </button>
                </div>
            </div>

        </>
    )
}

export default ProductCard