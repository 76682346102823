
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loading from '../Component/Common/Loading';
import StateDisctrict from '../Component/StateDisctrict';
import { useDataLayerValue } from '../DataLayer/DataLayer';
import firebase from '../firebase';
import '../style/registration.css';
import Header from '../Component/Common/Header/Header';
function RegistrationPage() {
    const [{ user, authInitilize }] = useDataLayerValue()
    const [formData, setFormData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 2000)
    }, [])

    let name, value;
    const userData = user.userData;
    const userAuthData = user.userAuthData;
    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }
    const registrUser = (e) => {
        e.preventDefault()
        var jsx = /^[A-Z a-z]+$/;
        var uid = userAuthData.uid
        if (formData.name.match(jsx)) {
            firebase.database().ref('users').child(uid).set({ ...formData, userId: uid, phone: userAuthData.phoneNumber, timeStamp: Date.now() }).then(() => {
                Swal.fire("User Registed Successfully!", '', 'success').then(() => { window.location.replace('/profile') })
            })
        }
        else {
            Swal.fire("Name Error", "Name should contain only letters in it no number are allowed", "error")
        }
    }


    if (authInitilize) {
        return <Loading />
    }
    else {
        if (userAuthData !== null) {
            if (userData === null) {
                return (
                    <>
                        <Header/>
                        <div className="registration">
                            <form onSubmit={registrUser}>
                                <div id="wrapper">
                                    <h5>Registration - पंजीकरण</h5>
                                    <div className="d-flex justify-content-center">
                                        <input required onChange={handleFormChanges} className="inputs-reg" type="text" name="name" id="name" placeholder="Your Name - अपना नाम" />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <select required name="userType" className="inputs-reg" onChange={handleFormChanges} id="inputState">
                                            <option value="">User type - उपयोगकर्ता का प्रकार</option>
                                            <option value="farmer"> Farmer - किसान</option>
                                            <option value="corporate"> Company/Organisation - कंपनी/संस्थान</option>
                                            <option value="consumer">I want to Buy (Consumer)</option>
                                        </select>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <input onChange={handleFormChanges} className="inputs-reg" type="email" name="email" id="email" placeholder="Email (optional) - ईमेल (वैकल्पिक)" />
                                    </div>
                                    <StateDisctrict formData={formData} handleFormChanges={handleFormChanges} />
                                    <div className="agree">
                                        <input required type="checkbox" name="agree" id="agree" />
                                        <label for="agree">
                                            <p>
                                                I agree to the <a href="/terms" target="_blank" rel="noopener noreferrer">terms and conditions</a> mentioned in <a href="/privacy-policy" target="_blank">privacy policy and agreement statement</a>.
                                            </p>
                                        </label>
                                    </div>
                                    <input className="theme-btn" value="Submit" type="submit" />

                                    <div id="recaptcha-container"></div>

                                </div>
                            </form>
                        </div>
                    </>
                )
            }
        }
        else {
            return (
                <div className="d-flex justify-content-center text-center align-items-center" style={{ height: "100vh", padding: "0 20px", flexDirection: "column" }}>
                    <h1>Please Login to access this page</h1>
                    <Link to={"/login"}>
                        <button className="btn btn-success">
                            Login
                        </button>
                    </Link>
                </div>
            )
        }
    }

}

export default RegistrationPage
