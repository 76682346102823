import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from "../../Component/Common/Header/Header";
import Loading from '../../Component/Common/Loading';
import ProductCard from '../../Component/Products/ProductCard';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import '../../style/Cart.css';
import '../../style/products.css';
import slugify from '../../util/common/slugify';
import InfoMessage from '../../Component/Common/UI Components/InfoMessage';
/*eslint-disable */
function Category() {
    const [{ products }] = useDataLayerValue()
    const Params = useParams()

    if (products.length) {
        return (
            <>
                <Header />
                <br /><br />
                <FilteredProducts categoryName={Params.category} />
            </>
        )
    }
    else {
        return <Loading />
    }
}


const FilteredProducts = ({ categoryName }) => {
    const [{ products }] = useDataLayerValue()
    const [itemList, setItemList] = useState([])
    useEffect(() => {
        const filteredData = products.filter(item => slugify(item.category).includes(categoryName?.toLowerCase()))
        setItemList(filteredData)

    }, [])

    if (itemList.length) {
        return (
            <>
                <div className="py-5 container" >
                    <hr />
                    <div>
                        <h3 className="text-center text-capitilize">
                            {itemList[0].category}
                        </h3>

                        <div className="row flex-wrap  justify-content-center" >
                            {

                                itemList.reverse().map((item, index) => {

                                    return (
                                        <div className="product-col col-lg-4 col-12">
                                            <ProductCard horizontal={true} index={index} data={item} />
                                        </div>
                                    )

                                })



                            }
                        </div>

                    </div>
                </div>
            </>
        )
    }
    else {
        return (
                <InfoMessage 
                    title="Products of this category coming soon to your area."
                    icon="fa-info-circle"
                    btnTxt="Continue Shopping"
                    href={"/"}
                />
        )
    }



}


export default Category;

