import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Swal from 'sweetalert2';
import Header from '../../Component/Common/Header/Header';
import Loading from '../../Component/Common/Loading';
import ProfileComon from '../../Component/ProfileComon';
import StateDisctrictEdit from '../../Component/StateDisctrictEdit';
import { Orders } from '../../Component/orders';
import { SellerAccount } from '../../Component/user';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import firebase from '../../firebase';
import '../../style/Profile.css';

/*eslint-disable */
function Profile() {
    const [{ user, authInitilize }] = useDataLayerValue();

    if (authInitilize || user.userData === undefined) {
        return <Loading />;
    } else {
        if (user.userAuthData === null) {
            window.location.replace('/login');
        }
        if (user.userData === null) {
            window.location.replace('/register');
        }
        return <ProfileSec />;
    }
}

const ProfileSec = () => {
    const [{ user }] = useDataLayerValue();
    const userData = user.userData;
    const isSeller = userData.userType === "farmer" || userData.userType === "corporate";

    return (
        <>
            <Header />
            <div className="row pt-5 mt-5 profile">
                <ProfileComon />
                <div className="col-md-8">
                    <Accordion style={{ marginBottom: 80 }} defaultActiveKey={isSeller ? "0" : "1"}>
                        <Accordion.Item style={{ display: !isSeller ? "none" : "block" }} eventKey="0">
                            <Accordion.Header>Seller Profile</Accordion.Header>
                            <Accordion.Body>
                                <SellerAccount />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Your Orders</Accordion.Header>
                            <Accordion.Body>
                                <Orders />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>General Profile</Accordion.Header>
                            <Accordion.Body>
                                <GeneralProfileSetting />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </>
    );
}

const GeneralProfileSetting = () => {
    const [{ user }] = useDataLayerValue();
    const userData = user.userData;

    const [formData, setFormData] = useState({
        address: userData.address,
        district: userData.district,
        email: userData.email,
        name: userData.name,
        phone: userData.phone,
        state: userData.state,
    });

    const handleFormChanges = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const removeUndefined = (obj) => {
        Object.keys(obj).forEach((key) => {
            if (obj[key] === undefined) {
                delete obj[key];
            }
        });
        return obj;
    };

    const updateProfile = (e) => {
        e.preventDefault();

        // Remove phone and conditionally remove email if not provided
        delete formData.phone;
        if (!formData.email) {
            delete formData.email;
        }

        // Remove undefined values from formData and userData
        const cleanFormData = removeUndefined(formData);
        const cleanUserData = removeUndefined(userData);

        

        // Merge clean data and update Firebase
        firebase.database().ref('users/').child(user.userAuthData.uid)
            .set({ ...cleanUserData, ...cleanFormData })
            .then(() => {
                Swal.fire("Your profile has updated successfully!", "", "success");
            })
            .catch((error) => {
                console.error("Error updating profile:", error);
                Swal.fire("Error updating profile. Please try again later.", "", "error");
            });
    };

    return (
        <form onSubmit={updateProfile} className="container profile-page-pro rounded bg-white mb-2">
            <div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text">General Profile Settings</h4>
                </div>
                <div className="row mt-2">
                    <div className="btn-block">
                        <label className="labels">Name</label>
                        <input
                            name="name"
                            onChange={handleFormChanges}
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value={formData.name}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label className="labels">Mobile Number</label>
                        <input
                            disabled
                            name="phone"
                            onChange={handleFormChanges}
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={formData.phone}
                        />
                    </div>
                    <div className="col-md-12">
                        <label className="labels">Email ID</label>
                        <input
                            name="email"
                            onChange={handleFormChanges}
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={formData.email}
                        />
                    </div>
                    <StateDisctrictEdit handleFormChanges={handleFormChanges} setFormData={setFormData} formData={formData} />
                </div>
                <div className="mt-5 text-center">
                    <button className="btn btn-width btn-primary profile-button" type="submit">
                        Update Profile
                    </button>
                </div>
            </div>
        </form>
    );
}

/*eslint-enable */
export default Profile;
