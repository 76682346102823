import Swal from "sweetalert2"
import firebase from "../../firebase"
/*
** for understanding of code **

add to cart
    
    if user is logedin
        if the given item is soled by corporate 
            add item to cart
        else if it is soled by a farmer
            check if the given item's state is == to currunt user's state
                add item to cart
        

    function add item to cart
        if item is already in cart
            show Swal(info) massage
        else
            add item to the user cart in firebase's data base

*/



const addToCart = ({ user, curruntUser, item, navigate }) => {

    return () => {
        if (!user.isLogedIn) {
            navigate('/login')
        }
        else if (!user.isRegisted) {
            navigate("/register")
        }
        else {
            validateSellerType(curruntUser, item, navigate)
        }
    }
}

const validateSellerType = (curruntUser, item, navigate) => {
    var currentQuantity = item.userType === "farmer" ? 100 : 1

    //if item is of a  corporate
    if (item.userType === "corporate") {
        addProductToCart({
            itemId: item.id,
            quantity: currentQuantity,
            curruntUser,
            navigate
        })
    }

    //if farmar
    else if (curruntUser.userData.state === item.state) {
        addProductToCart({
            itemId: item.id,
            quantity: currentQuantity,
            curruntUser,
            navigate
        })
    }
    else {
        Swal.fire("Sorry, we are about to operate in your region", "", "info")
    }


}

const isDublicate = (curruntUser, itemId) => {
    var userCartData = curruntUser.userData.cart
    var rawCartData = [];
    for (let id in userCartData) {
        rawCartData.push({ id, ...userCartData[id] })
    }
    var dublicate = rawCartData.filter((item) => item.itemId === itemId).length
    if (dublicate > 0) {
        return true
    }
    else {
        return false
    }
}
const addProductToCart = ({ itemId, quantity, curruntUser, navigate }) => {
    if (isDublicate(curruntUser, itemId)) {
        Swal.fire({
            title: "To increase the quantity to cart.",
            text: "",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Go to cart <i className="fa fa-shopping-cart"></i>'
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.replace("/cart")
            }
        })
    }
    else {
        firebase.database().ref('users').child(curruntUser.userAuthData.uid).child('cart').push({
            itemId,
            quantity
        }).then(() => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Item added to cart',
                html: "To place order go to cart",
                showConfirmButton: true,
                timer: 3000,
                confirmButtonText: "Go To Cart"

            }).then((result) => {
                if(result.isConfirmed){
                    navigate("/cart")
                }
            })
        }).catch((error) => {
            console.error(error.code)
        })
    }
}

export default addToCart