import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Loading from '../../Common/Loading';
import { useDataLayerValue } from '../../../DataLayer/DataLayer';
import firebase from '../../../firebase';
import fetchCrops from '../../../util/fetchCrops';
const FarmerProfileForm = () => {
    const [{ user, category }] = useDataLayerValue()
    const userData = user.userData;
    const farmerCrops1 = userData.farmerData.crops[0]
    const [crops, setCrops] = useState([])
    const [formData, setFormData] = useState([
    ])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 2000)
        setFormData(
            {
                ...farmerCrops1
            }
        )
    }, [])
    useEffect(() => {
        const loadData = async () => {
            setCrops(await fetchCrops())
        }
        loadData()
    }, [])
    let name, value;
    const userAuthData = user.userAuthData;
    const handleFormChanges = (event) => {
        name = event.target.name;
        value = event.target.value;
        setFormData({ ...formData, [name]: value });
    }
    const updateFarmerData = (e) => {
        e.preventDefault()

        var uid = userAuthData.uid
        var farmerData = {
            ...formData, crops: [{
                cropName: formData.cropName,
                cropType: formData.cropType,
                dateOfSowing: formData.dateOfSowing,
                fieldArea: formData.fieldArea,
                fieldSizeUnit: formData.fieldSizeUnit
            }],

        }

        delete farmerData.cropName
        delete farmerData.cropType
        delete farmerData.dateOfSowing
        delete farmerData.fieldArea
        delete farmerData.fieldSizeUnit
        firebase.database().ref('users').child(uid).child("farmerData").set({ ...userData.farmerData, ...farmerData, lastUpdate: Date.now() }).then(() => {
            Swal.fire("Farmer Profile Updated Successfully!", '', 'success')
        })
    }


    if (isLoading === true) {
        return <Loading />
    }
    else {

        if (userData.userType === "farmer") {

            if (user.userData.farmerData) {

                return (
                    <form onSubmit={updateFarmerData}>
                        <div className="container">
                            <span className="text-danger">note:keep updating profile in every three months if not will get deleted. <br />
                                प्रोफाइल को हर तीन महीने में अपडेट करते रहें, नहीं तो डिलीट हो जाएगा।
                            </span>
                            <br />
                            <div className="crops">
                                <h3>Crops</h3>
                                <div className="form-group">
                                    <select name="cropType" className="form-control" onChange={handleFormChanges} id="inputState">
                                        {
                                            category.map((item, index) => {
                                                if (item.categorieName === farmerCrops1.cropType) {
                                                    return <option key={index} defaultValue={true} value={item.categorieName}>{item.categorieName}</option>
                                                }
                                                else {
                                                    return <option key={index} value={item.categorieName}>{item.categorieName}</option>
                                                }

                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <select name="cropName" className="form-control"
                                    onChange={handleFormChanges} id="inputState">
                                    {
                                        crops.map((item, index) => {

                                            if (item.category === formData.cropType) {
                                                if (item.cropName === farmerCrops1.cropName) {
                                                    return <option key={index} defaultValue={true} value={item.cropName}>{item.cropName}</option>
                                                }
                                                else {
                                                    return <option key={index} value={item.cropName}>{item.cropName}</option>
                                                }
                                            }

                                        })
                                    }
                                </select>
                            </div>
                            <h5>
                                Date of Sowing
                            </h5>
                            <div className="form-group">
                                <input defaultValue={farmerCrops1.dateOfSowing} onChange={handleFormChanges} className="form-control" type="date" name="dateOfSowing" id="dateOfSowing" placeholder="Date of sowing" />
                            </div>
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="form-group">
                                        <input defaultValue={farmerCrops1.fieldArea} required onChange={handleFormChanges} className="form-control" type="number" name="fieldArea" placeholder="Are of your farm" />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <select name="fieldSizeUnit" className="form-control"
                                            onChange={handleFormChanges} id="inputState">
                                            <option defaultValue={farmerCrops1.fieldSizeUnit === "hectare" ? true : false} value="hectare">Hectare</option>
                                            <option defaultValue={farmerCrops1.fieldSizeUnit === "acre" ? true : false} value="acre">Acre</option>
                                            <option defaultValue={farmerCrops1.fieldSizeUnit === "bigha" ? true : false} value="bigha">Bigha</option>
                                            <option defaultValue={farmerCrops1.fieldSizeUnit === "katta" ? true : false} value="katta">Katta</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="selection-wrap">
                                <div className='selection-title'>
                                    <span className="en">Irrigation Facility? -</span> सिंचाई सुविधा?
                                </div>
                                <div className="radio-wrap">
                                    <input
                                        onChange={handleFormChanges}
                                        defaultChecked={userData.farmerData.irrigation === "yes" ? true : false}
                                        type="radio"
                                        id="irrigation_yes"
                                        name="irrigation"
                                        value={"yes"}
                                    /><label htmlFor="irrigation_yes">Yes</label>
                                    <input
                                        onChange={handleFormChanges}
                                        type="radio"
                                        id="irrigation_no"
                                        name="irrigation"
                                        defaultChecked={userData.farmerData.irrigation === "no" ? true : false}
                                        value={"no"}
                                    /><label htmlFor="irrigation_no">No</label>
                                </div>
                            </div>
                            <div className="selection-wrap">
                                <div className='selection-title'>
                                    <span className="en">Storage Facility? -</span> भंडारण सुविधा ?
                                </div>
                                <div className="radio-wrap">
                                    <input
                                        onChange={handleFormChanges}
                                        defaultChecked={userData.farmerData.storage === "yes" ? true : false}
                                        type="radio"
                                        id="storage_yes"
                                        name="storage"
                                        value={"yes"}
                                    /><label htmlFor="storage_yes">Yes</label>
                                    <input
                                        onChange={handleFormChanges}
                                        type="radio"
                                        id="storage_no"
                                        name="storage"
                                        defaultChecked={userData.farmerData.storage === "no" ? true : false}
                                        value={"no"}
                                    /><label htmlFor="storage_no">No</label>
                                </div>
                            </div>
                            <div className="selection-wrap">
                                <div className='selection-title'>
                                    <span className="en">Chemical fertilizers? -</span> रासायनिक खाद ?
                                </div>
                                <div className="radio-wrap">
                                    <input required onChange={handleFormChanges} defaultChecked={userData.farmerData.chemicalFertilizers === "yes" ? true : false} type="radio" id="chemical_fertilizer_yes" name="chemicalFertilizers" value={"yes"} /><label htmlFor="chemical_fertilizer_yes">Yes</label>
                                    <input required onChange={handleFormChanges} defaultChecked={userData.farmerData.chemicalFertilizers === "no" ? true : false} type="radio" id="chemical_fertilizer_no" name="chemicalFertilizers" value={"no"} /><label htmlFor="chemical_fertilizer_no">No</label>
                                </div>
                            </div>
                            <div className="selection-wrap">
                                <div className='selection-title'>
                                    <span className="en">Soil tested? -</span> मिट्टी जांच ?
                                </div>
                                <div className="radio-wrap">
                                    <input required onChange={handleFormChanges} defaultChecked={userData.farmerData.soilTested === "yes" ? true : false} type="radio" id="soil_tested_yes" name="soilTested" value={"yes"} /><label htmlFor="soil_tested_yes">Yes</label>
                                    <input required onChange={handleFormChanges} defaultChecked={userData.farmerData.soilTested === "no" ? true : false} type="radio" id="soil_tested_no" name="soilTested" value={"no"} /><label htmlFor="soil_tested_no">No</label>
                                </div>
                            </div>
                            <br />
                            <input className="theme-btn" value="Update" id="submit" type="submit" />
                            <br /><br />

                        </div>
                    </form>
                )
            }
        }
    }

}

export default FarmerProfileForm