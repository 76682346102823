import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Header from "../../Component/Common/Header/Header"
import Loading from '../../Component/Common/Loading'
import { useDataLayerValue } from '../../DataLayer/DataLayer'
import firebase from '../../firebase'
import '../../style/placeOrder.css'
import {
    BsDashCircle,
    BsPencilSquare,
    BsPlusLg,
    BsDashLg
} from "react-icons/bs";
import Footer from '../../Component/Footer'
/*eslint-disable */

const PlaceOrder = () => {
    const [{ authInitilize, user }] = useDataLayerValue()

    if (authInitilize || user.userData === undefined) {
        return <Loading />
    }

    else if (user.userAuthData === null) {
        return (<>
            <Header />
            <div className="no-login-cart">
                <div className="circle">
                    <i className="fa fa-arrow-right-to-bracket"></i>
                </div>
                <h3>Please Login to see cart</h3>
                <Link to={'/login'}><button className="btn btn-success">Login <i className="fa fa-arrow-right-to-bracket"></i></button></Link>
            </div>
        </>)
    }
    else if (user.userData === null) {
        return (
            <>
                <Header />
                <div className="no-login-cart">
                    <div className="circle">
                        <i className="fa fa-user"></i>
                    </div>
                    <h3>Please Register to Place Order</h3>
                    <Link to={'/register'}><button className="btn btn-success">Register</button></Link>
                </div>
            </>
        )
    }
    else {

        return (<PlaceOrderComp />)

    }
};
function PlaceOrderComp() {
    const [{ user, products }] = useDataLayerValue()
    const userData = user.userData
    if (user.userData === null || user.userAuthData === null) {
        window.location.replace("/home")
    }

    var userCartData = user.userData.cart
    var rawCartData = [];
    for (let id in userCartData) {
        rawCartData.push({ id, ...userCartData[id] })
    }
    var TEMP_TOTAL_ARR = []
    rawCartData.map((item) => {
        products.map((product, index) => {
            if (item.itemId === product.id) {
                TEMP_TOTAL_ARR.push({ ...product, price: parseFloat(product.price), itemQuantity: parseInt(item.quantity), itemId: product.id })
            }
        })
    })
    var cartTotalPrice = 0;
    for (let i in TEMP_TOTAL_ARR) {
        if (TEMP_TOTAL_ARR[i].category === "Farm Machinery - कृषि मशीनरी" || TEMP_TOTAL_ARR[i].category === "Fertilizers & Pesticides - उर्वरक & कीटनाशक") {
            cartTotalPrice = cartTotalPrice + (parseInt(TEMP_TOTAL_ARR[i].price) * TEMP_TOTAL_ARR[i].itemQuantity)
        }
        else {
            cartTotalPrice = Math.fround(cartTotalPrice + ((TEMP_TOTAL_ARR[i].price + TEMP_TOTAL_ARR[i].price * (5 / 100)) * TEMP_TOTAL_ARR[i].itemQuantity))

        }
    }
    const proceedOrder = () => {
        const tempArr = []
        rawCartData.map((item) => {
            products.map((product, index) => {
                if (item.itemId === product.id) {
                    tempArr.push({ name: product.name, imgUrl: product.imgUrl, sellerId: product.sellerUID, price: product.price, ...item, userId: user.userAuthData.uid, timeStamp: Date.now() })
                }
            })
        })
        tempArr.map((item) => {
            firebase.database().ref("orders/").push({ ...item, id: null }).then((snap) => {
                firebase.database().ref("users").child(item.sellerId).child("orders_got").child(snap.key).set({ ...item, id: null, sellerId: null })
                firebase.database().ref("users/").child(user.userAuthData.uid).child("orders").child(snap.key).set({ ...item, id: null })
            })

        })
        firebase.database().ref("users/").child(user.userAuthData.uid).child("cart").remove().then(() => {
            Swal.fire("Order placed successfully", "", "success").then(() => {
                window.location.replace("/home")
            })
        })

    }
    return (
        <>
            <Header />
            <div className="container" style={{ marginTop: 80 }}>
                <div className="row">
                    <div className="p-3 col-lg-7 col-md-12">
                        <div className="card p-4">
                            <h3 className="text-success">Payment Method</h3>
                            <h6 className="px-1">Cash on Delivery</h6>
                            <hr />
                            <h3 className="text-success">
                                Shipping Address <Link to="/profile/address">
                                    <BsPencilSquare className="pb-1" />
                                </Link>
                            </h3>

                            {
                                userData.address_line_1 ?
                                    <h6 className="px-1">
                                        {userData.address_line_1},
                                        <br />
                                        {userData.address_line_2},
                                        <br />
                                        {userData.block && `${userData.block},`}{userData.district},
                                        <br />
                                        {userData.state}
                                    </h6>
                                    :
                                    <div className='info-page'>
                                        <h4>
                                            You Didn't givin us your Shipping Address
                                        </h4>
                                        <p>
                                            You can update you address by clicking button bellow.
                                        </p>
                                        <Link to={"/profile/address"}>
                                            <button className="btn btn-success mt-3">
                                                Update Shipping Address
                                            </button>
                                        </Link>

                                    </div>
                            }
                        </div>
                    </div>
                    <div className="p-3 col-lg-5 col-md-12">
                        <div className="card p-4">
                            <h3 className="text-success">Order Summary</h3>
                            <div className="row">
                                {/* <div className="col-6 text-success">
                                    <h5>Product 1</h5>
                                    <p className="text-secondary">without 5% GST</p>
                                </div>
                                <div className="col-6 text-success text-end">
                                    <h5>60.00</h5>
                                    <p className="text-secondary">57.00</p>
                                </div> */}
                                <div>
                                    {
                                        rawCartData.map((item, index1) => {
                                            return (
                                                <>
                                                    {
                                                        products.map((product, index) => {
                                                            if (item.itemId === product.id) {
                                                                return (
                                                                    <div className='row'>
                                                                        <div className="col-6 ">
                                                                            <h5 className='text-success'>{product.name} </h5>
                                                                            {

                                                                                (product.category === "Farm Machinery - कृषि मशीनरी" || product.category === "Fertilizers & Pesticides - उर्वरक & कीटनाशक") ?
                                                                                    <p>{product.price} x {item.quantity}/{product.unit}</p>
                                                                                    :
                                                                                    <p>
                                                                                        {
                                                                                            (((parseInt(product.price)) + (parseInt(product.price) * (5 / 100)))).toFixed(2)
                                                                                        }
                                                                                        x {item.quantity}/{product.unit}
                                                                                    </p>

                                                                            }

                                                                        </div>
                                                                        <div className="col-6 text-success text-end">
                                                                            <h5> &#8377;
                                                                                {

                                                                                    (product.category === "Farm Machinery - कृषि मशीनरी" || product.category === "Fertilizers & Pesticides - उर्वरक & कीटनाशक") ? `${product.price * item.quantity}` :
                                                                                        <>
                                                                                            {(((parseInt(product.price)) + (parseInt(product.price) * (5 / 100))) * item.quantity).toFixed(0)}
                                                                                        </>

                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                            <p>
                                <div className="row">
                                    <div className="col-6">
                                        <h4 className="text-success">Net Total</h4>
                                    </div>
                                    <div className="col-6 text-end">
                                        <h4 className="text-success">&#8377; {cartTotalPrice}</h4>
                                    </div>
                                    <p className="text-secondary">
                                        Including GST on Applicable Products
                                    </p>
                                    <button onClick={proceedOrder} className="btn btn-success btn-block">
                                        Place Order
                                    </button>
                                    <p className="text-danger">
                                        Transportation and Logistics as per Applicable*
                                    </p>
                                </div>
                            </p>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default PlaceOrder 