import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDataLayerValue } from '../../../../DataLayer/DataLayer';
import "../../../../style/kd-index.css";
import '../../../../style/navbar.css';
import {
    BsGrid3X3GapFill, BsFillBasket3Fill,
    BsFillPersonFill,
    BsSearch
} from "react-icons/bs"
const BottomNav = () => {
    const [{ user }] = useDataLayerValue()
    const navigate = useNavigate()
    const userData = user.userData
    const [preferanceTxt, setPreferanceTxt] = useState("My Profile")
    useEffect(() => {
        if (userData && user.userAuthData) {
            if (userData.userType === "farmer" || userData.userType === "corporate") {
                setPreferanceTxt("My Store")
            }
        }
        else {
            setPreferanceTxt("My Profile")
        }
    }, [])

    return (
        <>
            <section className="bottom-nav bg-light">
                <div className="container ">
                    <div className="row text-center text-success">
                        <div className="col-3 py-2">
                            <Link to={"/categories"}>
                                <div className="btm-nav-links">
                                    <BsGrid3X3GapFill className="btm-nav-icon" />
                                    <br />
                                    Categories
                                </div>
                            </Link>
                        </div>
                        <div className="col-3 py-2">
                            <Link to="/search" className="btm-nav-links">
                                <BsSearch className="btm-nav-icon" />
                                <br />
                                Search
                            </Link>
                        </div>
                        <div className="col-3 py-2">
                            <div onClick={
                                userData && user.userAuthData ?
                                    userData.userType === "farmer" || userData.userType === "corporate" ?
                                        () => { navigate("/my-store") }
                                        :
                                        () => {
                                            navigate("/profile")
                                        }
                                    :
                                    () => {
                                        navigate("/profile")
                                    }

                            } className="btm-nav-links">
                                <BsFillPersonFill className="btm-nav-icon" />
                                <br />
                                Profile
                            </div>
                        </div>
                        <div className="col-3 py-2">
                            <Link to="/cart" className="btm-nav-links">
                                <BsFillBasket3Fill className="btm-nav-icon" />
                                <br />
                                Cart
                            </Link>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
export default BottomNav