import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from "../../Component/Common/Header/Header"
import ProfileComon from '../../Component/ProfileComon'
import { useDataLayerValue } from '../../DataLayer/DataLayer'

function Orders() {
    const [{ user }] = useDataLayerValue()
    if (user.userAuthData) {
        if (user.userData) {
            return <TempComp />
        }
        else {
            window.location.replace("/")
        }
    }
    else {
        window.location.replace("/")
    }
}
const TempComp = () => {
    const [{ user }] = useDataLayerValue()
    const [userOrders, setUserOrder] = useState([])
    useEffect(() => {
        var tempArr = []
        for (let id in user.userData.orders) {
            tempArr.push({ id: id, ...user.userData.orders[id] })
        }
        setUserOrder(tempArr)
    }, [])

    return (
        <body>
            <Header />
            <br />
            <br />
            <div className="container rounded bg-white mt-5 mb-5">
                <div className="row">
                    <ProfileComon />
                    <div className="col-md-8 ">
                        <div className="p-3 py-5 d-flex flex-wrap justify-content-between">
                            {
                                userOrders.length ? userOrders.map((item, index) => {
                                    return (
                                        <OrdersCard item={item} index={index} />
                                    )
                                })
                                    :
                                    <>
                                        <div className="no-login-cart" style={{ justifyContent: 'start' }}>
                                            <div className="circle">
                                                <i className="fa fa-shopping-bag"></i>
                                            </div>
                                            <h3 className="text-center">You haven't order any thing</h3>
                                            <Link to={'/home'}><button className="btn btn-success">Continue Shopping <i className="fa fa-arrow-right-to-bracket"></i></button></Link>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>



        </body>
    )
}
const OrdersCard = (props) => {
    const [{ products }] = useDataLayerValue()
    var item = props.item
    // item = products.filter(item => item.id === props.item.itemId)
    // item = item[0]
    var timeStamp = props.item.timeStamp
    var deleveryDate = parseInt(new Date(timeStamp).getDate())
    var deleveryMonth = parseInt(new Date(timeStamp).getMonth())
    var deleveryYear = parseInt(new Date(timeStamp).getFullYear())
    return (
        <div className="product">
            <div className="img-box-pro">
                <img src={item.imgUrl} alt="wheat" className="pro-img" />
                {
                    item.organic === "yes" && <><div className="green-box"></div></>
                }
            </div>
            <div className="pro-detail">
                <h3 className="pro-name">{item.name}</h3>
                <h3 className="pro-price"> ₹ {item.price}</h3>
                <b>Status:</b> {
                    item.status ? item.status : <>
                        Order Place <br />
                    </>
                }
                {
                    item.status === "accepted" && <>
                        <b> Estimated Days to ready the order:</b> {item.estimatedDays}
                    </>
                }
                {/* <h3 className='pro-price'>Estimated Delivery Date <b>{`${deleveryDate + 3}/${deleveryMonth + 1}/${deleveryYear}`}</b></h3> */}
            </div>
        </div>
    )
}
export default Orders
export { OrdersCard }