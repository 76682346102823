import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Footer'

const InfoMessage = ({ title, href, btnTxt, icon, desc = "" }) => {
    return (
        <>
            <div className='info-page'>
                <i className={`fa-solid ${icon}`}>
                </i>
                <h3>
                    {title}
                </h3>
                <p>
                    {desc}
                </p>
                {
                    btnTxt && <Link to={href}>
                        <button className="btn btn-success mt-3">
                            {btnTxt}
                        </button>
                    </Link>
                }
            </div>

        </>
    )
}

export default InfoMessage