import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from "../../../Component/Common/Header/Header";
import Loading from '../../../Component/Common/Loading';
import { useDataLayerValue } from '../../../DataLayer/DataLayer';
import slugify from '../../../util/common/slugify';
import ProductCard from '../../../Component/Products/ProductCard';
import '../../../style/Cart.css';
import '../../../style/products.css';
import InfoMessage from '../../../Component/Common/UI Components/InfoMessage';
function FilteredProductList() {
    const [{ products }] = useDataLayerValue()
    const Params = useParams()

    if (products.length) {
        return (
            <>
                <Header />
                <br /><br />
                <FilteredProducts productName={Params.product} />
            </>
        )
    }
    else {
        return <Loading />
    }
}


const FilteredProducts = ({ productName }) => {
    const [{ products }] = useDataLayerValue()
    const [itemList, setItemList] = useState([])
    useEffect(() => {
        const filteredData = products.filter(item => slugify(item.name).includes(productName?.toLowerCase()))
        setItemList(filteredData)
    }, [])

    if (itemList.length) {
        return (
            <>
                <div className="py-5 " >
                    <hr />
                    <div>
                        <h3 className="text-center text-capitilize">
                            {itemList[0].name}
                        </h3>

                        <div className="card-wrap flex-wrap justify-content-center" >
                            {

                                itemList.reverse().map((item, index) => {

                                    return (
                                        <>
                                            <ProductCard index={index} data={item} />
                                        </>
                                    )

                                })



                            }
                        </div>

                    </div>
                </div>
            </>
        )
    }
    else {
        return (
            <InfoMessage
                title="Products of this category coming soon to your area."
                icon="fa-info-circle"
                btnTxt="Continue Shopping"
                href={"/"}
            />
    )
    }



}

export default FilteredProductList;
