// eslint-disable-next-line
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from "../../../assets/images/logo.jpeg";
import { useDataLayerValue } from '../../../DataLayer/DataLayer';
import firebase from '../../../firebase';
import "../../../style/kd-index.css";
import '../../../style/navbar.css';
import BottomNav from './Navigation/BottomNav';
import DeskNav from './Navigation/DeskNav';
function Header() {
    const [{ user }] = useDataLayerValue()
   
    return (
        <>
            <nav className="navbar px-4 navbar-expand-lg  justify-content-between navbar-dark bg-dark">
                <NavLink className="navbar-brand" to="/"><img style={{ width: "30px", height: "30px", borderRadius: "50%" }} src={Logo} alt="" /> <span style={{ marginLeft: "10px" }} >eKisan Darshan</span></NavLink>
                <i className="fa fa-search" aria-hidden="true"></i>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse d-lg-flex justify-content-end navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ">
                        <li className="nav-item active">
                            <NavLink className="nav-link"  to="/">Home <span className="sr-only">(current)</span></NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/about">About Us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/search">
                            <i className="fa fa-search"/> Search
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            {
                                <NavLink className="nav-link" to="/cart"><i className="fa fa-shopping-cart"></i> Cart</NavLink>
                            }
                        </li>

                        <li className="nav-item">
                            {user.userAuthData === null ?
                                ""
                                :
                                <NavLink className="nav-link" to="/profile"><i className="fa fa-user"></i> Profile</NavLink>
                            }
                        </li>
                        <li className="nav-item">
                            {
                                user.userAuthData === null ?
                                    <div className="nav-link" style={{ cursor: "pointer" }} onClick={() => { window.location.replace('/login') }} >
                                        Login
                                    </div>
                                    : <div className="nav-link" style={{ cursor: "pointer" }} onClick={() => { firebase.auth().signOut().then(() => { window.location.reload() }) }} >
                                        Sign Out
                                    </div>
                            }
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/contact">Contact us</NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
            <DeskNav/>
            <nav className="more-options">
                <Link to="/categories">
                    <button className='btn btn-width btn-primary p-2 ml-5 cat-button'>
                        Categories
                    </button>
                </Link>
            </nav>
            <BottomNav/>
        </>
    );
}


export default Header;
